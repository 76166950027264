import React , {useState, useRef, useEffect}  from "react"
import Filters from './filters'
import Results from './results'

import Seo from "../../components/seo"
import HandlerBar from './handler-bar'

import {getType,getOperationView} from '../../helpers/helper.filters'
import { useLocation } from '@reach/router';
import Breadcrumbs from "../breadcrumbs";
import Map from './map-results'

import {redux_updateOrders} from '../../redux/propertiesDucks'

import { connect } from 'react-redux'

import { graphql, useStaticQuery } from 'gatsby';

const wrapper = ({filters,totalCount,loading,dispatch}) => {
    
    const [menu,setMenu] = useState(false)
    const [viewId,setViewId] = useState(2) // 0: list-map, 1: map, 2: grid
    const [orders,setOrders] = useState({order:'desc',order_by:'id'})
    const selectorOrder = useRef()

    const textTypes = (types) => {
        let text = ''
        types.map((type,index) => {
            text += type.name
            if(type.name[type.name.length-1] === 'n' || type.name[type.name.length-1] === 'l' || type.name[type.name.length-1] === 's'){
                text += 'es'
            }
            else{
                text += 's'
            }
            if(type.name[type.name.length-1] === 'h'){
                text += "s"
            }
            if(index < types.length - 1) text += ', '
        })
        return text
    }

    const typeTitle = (type)  => {
        if(type === '' || type.length === 0){return "Propiedades"}
        else return textTypes(type)
    }

    function traductorSelect(){
        switch(selectorOrder.current.value){
          case '0': setOrders({order:'desc',order_by:'price'}); break;
          case '1': setOrders({order:'asc',order_by:'price'}); break;
          case '2': setOrders({order:'desc',order_by:'id'}); break;
          case '3': setOrders({order:'asc',order_by:'id'}); break;
          case '4': setOrders({order:'desc',order_by:'total_surface'}); break;
          case '5': setOrders({order:'asc',order_by:'total_surface'}); break;
        }
    }

    useEffect(() => {
    dispatch(redux_updateOrders(orders))
    },[orders])

    useEffect(() => {
        var screenWidth = screen.width
        if(screenWidth < 992){
            setViewId(2)
        }
    },[])

    const textUpper = (text) => text?.replace(text[0], text[0]?.toUpperCase())
    return(
        <>
            <div id="properties" class="container-fluid pb-lg-0">
                <p onClick={() => setMenu(!menu)} id="openFilters" 
                    class="d-none d-lg-none align-items-center justify-content-center">
                        <i class="icon-filter me-4"></i><span>FILTRAR</span>
                </p>
            </div>
            <HandlerBar setViewId={setViewId} viewId={viewId} setMenu={setMenu} menu={menu} />
            <Filters setViewId={setViewId} viewId={viewId} menu={menu} setMenu={setMenu} />
            {viewId === 2 
            ?   <div className="container-fluid">
                    <div class="row header-resultados fixed-mobile">
                        <div className="col-12">
                            {
                                <Breadcrumbs
                                    props={[
                                        { name: "Home", route: "/", location: "" },
                                        { name: getOperationView(filters), route:   "", location: ""},
                                    ]}
                                    skeleton={getOperationView(filters) ? false : true}
                                    margin={true}
                                />
                            }
                        </div>
                        <div class="col-lg-6 mt-4 mt-lg-0 content-section-title d-flex align-items-end">
                            <div class="section-title">
                                {loading && <h1 className="mb-0">Cargando propiedades..</h1>}
                                {!loading &&
                                    <h1 className="mb-0">{loading ? 'Cargando' : totalCount} {getType(filters)?.length > 0 ? textTypes(getType(filters)) + " en " : 'Propiedades en'}{' '} 
                                    <span className="">{" "  + textUpper(getOperationView(filters))}</span></h1>
                                }
                                {/* <h5>{loading ? 'Cargando' : totalCount} resultados totales de búsqueda. </h5> */}
                            </div>
                            {/* <div class="d-block d-lg-none section-title">
                                {loading && <h1>Cargando propiedades..</h1>}
                                {!loading &&
                                    <h1 className="">{'Propiedades en'}{' '} 
                                    <span>{textUpper(getOperationView(filters))}</span></h1>
                                }
                                <h5>{loading ? 'Cargando' : totalCount} resultados totales de búsqueda. </h5>
                            </div> */}
                        </div>
                        <div class="mt-2 mt-lg-0 px-0 px-lg-2 col-lg-6 d-flex align-items-center justify-content-end pe-lg-4" id="orderBy">
                            Ordenar por:
                            <div class="content-select pe-0 ps-0 ps-lg-0">
                                <select ref={selectorOrder} onChange={() => traductorSelect()} name="orderby" id="sort">
                                    <option value="2">Publicaciones más recientes</option>
                                    <option value="3">Publicaciones más antiguas</option>
                                    <option value="0">Mayor precio</option>
                                    <option value="1">Menor precio</option>
                                    <option value="4">Mayor superficie</option>
                                    <option value="5">Menor superficie</option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                    </div>
                    <div class="row position-relative div-grid">
                        {/* <Filters menu={menu} setMenu={setMenu} /> */}
                        <Results viewId={viewId} menu={menu} setMenu={setMenu} />
                    </div>
                </div>
            : 
            ''
            }
            <>
                {/* <div className={"second-view " + (viewId === 2 ? 'zIndexDown' : '') }> */}
                <div className={"second-view " + (viewId === 2 ? 'zIndexDown' : '') }>
                    <Map menu={menu} setMenu={setMenu} viewId={viewId} />
                    {/* <Filters menu={menu} setMenu={setMenu} hidden={true} /> */}
                </div>
            </>   
            
        </>
    )
}

export default connect(state => ({
    filters: state.properties.filters,
    totalCount: state.properties.totalCount,
    loading: state.properties.loading
}),null)(wrapper);
