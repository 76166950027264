import React, { useEffect } from "react"
import { Router, RouteComponentProps } from "@reach/router"
import ErrorPage from "./404"
import Propiedades from "../templates/propiedades"

const App = () => {
  return (
    <Router basepath="/">
      <Propiedades path="/venta/" />
      <Propiedades path="/venta/:param-1" />
      <Propiedades path="/venta/:param-1/:param-2" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4/:param-5" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8" />
      <Propiedades path="/venta/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9" />

      <Propiedades path="/alquiler/" />
      <Propiedades path="/alquiler/:param-1" />
      <Propiedades path="/alquiler/:param-1/:param-2" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4/:param-5" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8" />
      <Propiedades path="/alquiler/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9" />

      <Propiedades path="/alquiler-temporario/" />
      <Propiedades path="/alquiler-temporario/:param-1" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4/:param-5" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8" />
      <Propiedades path="/alquiler-temporario/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9" />
      {/* //Alquiler// */}
      <ErrorPage default />
    </Router>
  )
}
export default App
