import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {getListFilters,getTotalListFilters,getPropertiesAction,REDUX_UPDATE_FILTERS,clearPropertiesAction,getCustomTags, getFullPropertiesAction, changeSelectedAction, getListLocations, getListDynamicFilters, REDUX_UPDATE_DRAWN_POLYGON, getAllLocationsAction, getListLocationsAction } from '../../redux/propertiesDucks'
import { useLocation } from '@reach/router';
import {
    getCustoms,
    getServices,
    getEnvironment,
    getGenerals,
    checkCurrentFilters,
    checkOperation,
    updateFilters,
    getView,
    updateRouter,
    loadFiltersRoute,
    getLocationCount,
    getTypeCount,
    getTagCount,
    getCustomTagCount,
    checkEnvironment,
    makeCustom
} from '../../helpers/helper.filters'

const Filter = ({_filters,polygonCoordinates,tokko_filters,total_tokko_filters,listLocationFilters,loading_total_filters,loading_locations,allLocations,loading_Alllocatons,drawnPolygon,totalCountAlquilerTemp,listDynamicFilters,totalCountAlquilerTemporario,totalCountAlquiler,totalCountVenta,orders,api_key,totalCount,offset,custom_tags,dispatch,menu,setMenu,setViewId,viewId}) => {
    const history = useLocation();
    const pricesDefault = [20000,40000,60000,80000,100000,200000,400000,600000,800000,1000000]
    const [helpers,setHelpers] = useState({minprice:0,maxprice:0,minarea:0,maxarea:0}) // minprice,maxprice,minarea,maxarea
    const [location,setLocation] = useState(history.pathname.split("/"))
    const [filtersLoading,setFiltersLoading] = useState(true)
    // const [menu,setMenu] = useState(false)
    const [auxiliarValuesPrice,setAuxiliarPrice] = useState({
            priceType:'',
            priceMin:'',
            priceMax:'',
    })
    const [auxiliarValuesSurface,setAuxiliarSurface] = useState({
            surfaceType:'',
            surfaceMin:'',
            surfaceMax:'',
    })

    const [environments,setEnvironments] = useState({min:'',max:''})
    const [openMinEnvironment,setOpenMinEnvironment] = useState(false)
    const [openMaxEnvironment,setOpenMaxEnvironment] = useState(false)

     //Current Filter Array
    const [filters,setFilters] = useState({
        location:[],
        type:[],
        operation:getView(history),
        environments:'',
        bedrooms: "",
        age:'',
        price:{
            type:'',
            priceMin:'',
            priceMax:''
        },
        surface:{
            type:'',
            surfaceMin:"",
            surfaceMax:"",
        },
        environments_types:[],
        generals:[],
        services:[],
        specials:[],
    })
  
    useEffect(() => {
        // Ejecuto la accion para obtener los filtros
        if(total_tokko_filters.data && allLocations){
            if(location){
                loadFiltersRoute(location,setFilters,filters,total_tokko_filters.data,[],setFiltersLoading,allLocations)
            }
        }
        else{
            if(api_key){
                // if(!allLocations?.meta){
                // }
                if(!allLocations.meta){
                    dispatch(getListLocationsAction([1,2,3],[]))
                }
                if(!loading_total_filters){
                    // dispatch(getTotalListFilters())
                }
                // dispatch(getCustomTags())
            }
        }
    } , [total_tokko_filters,api_key,allLocations])

    useEffect(() => {
        // Cada vez que cambia un filtro, actualizo el almacen de redux 
        if(!filtersLoading){
            if(api_key){
                dispatch(REDUX_UPDATE_FILTERS(filters))
                dispatch(clearPropertiesAction())
                updateRouter(filters)
                dispatch(getPropertiesAction())
                // dispatch(getFullPropertiesAction())
                setEnvironments(checkEnvironments())
                dispatch(getListFilters())
                dispatch(getListLocations())
                dispatch(getListDynamicFilters())
                if(checkCurrentFilters(filters,"surface-type","total")){
                    setAuxiliarSurface({...auxiliarValuesSurface, 
                        surfaceType : "total",
                        surfaceMin : filters.surface.surfaceMin,
                        surfaceMax : filters.surface.surfaceMax
                    })
                }
                if(checkCurrentFilters(filters,"surface-type","cubierta")){
                    setAuxiliarSurface({...auxiliarValuesSurface, 
                        surfaceType : "cubierta",
                        surfaceMin : filters.surface.surfaceMin,
                        surfaceMax : filters.surface.surfaceMax
                    })
                }
                if(checkCurrentFilters(filters,"price-type","pesos")){
                    setAuxiliarPrice({...auxiliarValuesPrice, 
                        priceType : "pesos",
                        priceMin : filters.price.priceMin,
                        priceMax : filters.price.priceMax
                    })
                }
                if(checkCurrentFilters(filters,"price-type","dolares")){
                    setAuxiliarPrice({...auxiliarValuesPrice, 
                        priceType : "dolares",
                        priceMin : filters.price.priceMin,
                        priceMax : filters.price.priceMax
                    })
                }
            }
        }
    },[filters,orders,filtersLoading,api_key,history.pathname,polygonCoordinates])

    useEffect(() => {
        if(_filters.operation && _filters !== filters && checkContent(filters)){
            setFilters(_filters)
        }
        dispatch(changeSelectedAction(0))
    },[_filters,offset])

    useEffect(() => {
        if(history.pathname.includes("temp")){
            updateFilters(setFilters,filters,"operation", "alquiler-temporario")
        }
        else if(history.pathname.includes("venta")){
            updateFilters(setFilters,filters,"operation", "venta")
        }
        else{
            updateFilters(setFilters,filters,"operation", "alquiler")
        }
    },[history.pathname])

    const checkContent = (filters) => {
        const prot_filter = {
            location: [],
            type: [],
            operation: "",
            environments: '',
            bedrooms: "",
            price:{
              type:'',
              priceMin:'',
              priceMax:''
            },
            surface:{
              type:'',
              surfaceMin:"",
              surfaceMax:"",
            },
            environments_types: [],
            generals: [],
            services: [],
            specials: [],
        }
        if(filters === prot_filter){
            return false
        }
        return true
    }

    const sendAuxiliar = () => {
        updateFilters(setFilters,filters,"price-type", auxiliarValuesPrice.priceType)
        updateFilters(setFilters,filters,"price-min", auxiliarValuesPrice.priceMin)
        updateFilters(setFilters,filters,"price-max", auxiliarValuesPrice.priceMax)
        updateFilters(setFilters,filters,"surface-type", auxiliarValuesSurface.surfaceType)
        updateFilters(setFilters,filters,"surface-min", auxiliarValuesSurface.surfaceMin)
        updateFilters(setFilters,filters,"surface-max", auxiliarValuesSurface.surfaceMax)
    }

    useEffect(() => {
        let interval = ''
        if(filters.environments !== (`${environments.min}-${environments.max}`)){
            if(environments.min || environments.max) {
                if(environments.min !== '' || environments.max !== ''){
                    interval = (`${environments.min}-${environments.max}`)
                }
                if (environments.min !== '' && environments.max === ''){
                    interval = (`${environments.min}-99`)
                }
                if(environments.max !== '' && environments.min === ''){
                    interval = (`0-` + environments.max)
                }
                return updateFilters(setFilters,filters,"environments",interval)
            }
        }
    },[environments])

    const checkEnvironments = () => {
        const [min,max] = filters.environments !== '' ?  filters.environments.split("-") : [0,0]
        return {min,max}
    }

    const updateEnvironment = (type,value) => {
        if(type === 'min'){
            return setEnvironments({...environments,min:value})
        }
        if(type === 'max'){
            return setEnvironments({...environments,max:value})
        }
    }

    return  (
        <aside id="filters" className={"col-lg-4 " + (menu ? ' filterOn blur ' : '') + (viewId === 0 ? ' view0 ' : viewId === 1 ? ' view1 ' : viewId === 2 ? ' view2 ' : '')}>
            {
                tokko_filters.data && total_tokko_filters.data && listLocationFilters.data && listDynamicFilters.data && allLocations !== []
                ?   <form className={"filters-container filter-form " }>
                        <input type="hidden" name="page" value="1" />
                        <div className="filters-header mt-3">
                            <div className="filters-close-wrap mt-2 text-right justify-content-between algin-items-center d-flex">
                                <h2>Filtros de búsqueda</h2>
                                <div id="closeFilter" className="end" >
                                    <div onClick={() => setMenu(false)} className="filters-close mt-2 text-right justify-content-right algin-items-start" style={{cursor:"pointer"}}><span className="d-block"><i></i><i></i></span></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="current-filters py-3">
                                    {(<div className="d-inline-block">{<div className={"tag tag-button d-inline-flex align-items-center justify-content-center " + (filters.operation.toLowerCase())}>{filters.operation}</div>}</div>)}
                                    {filters.location.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'location',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                    {filters.type.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'type',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                    {filters.price.type !== '' ? (<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.price.type} <div onClick={() =>  updateFilters(setFilters,filters,'price-type',"null")} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>):''}
                                    {(filters.price.type !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">Desde: $ {filters.price.priceMin} Hasta: $ {filters.price.priceMax} <div onClick={() =>  updateFilters(setFilters,filters,'price-type',"null")} className="btn-delete-tag cursor-pointer ms-3">×</div></div> : '')}
                                    {filters.surface.type !== '' ?  (<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.surface.type} <div onClick={() =>  updateFilters(setFilters,filters,'surface-type','')} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>):''}
                                    {(filters.surface.type !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">Desde: {filters.surface.surfaceMin} M2 Hasta: {filters.surface.surfaceMax} M2<div onClick={() =>  updateFilters(setFilters,filters,'surface-type','')} className="btn-delete-tag cursor-pointer ms-3">×</div></div> : '')}
                                    {/* {filters.environments.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item} <div onClick={() =>  updateFilters(setFilters,filters,'environments',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))} */}
                                    {(filters.age && filters.age !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.age} {filters.age?.includes("años") ? ' de antigüedad' : ''}<div onClick={() =>  updateFilters(setFilters,filters,'age','')} className="btn-delete-tag cursor-pointer ms-3">×</div></div> : '')}
                                    {filters.environments !== '' ? (
                                        <div className="d-inline-block">{
                                            <div className="tag tag-button d-inline-flex align-items-center justify-content-center">
                                                {
                                                    filters?.environments?.split("-")[0] === filters?.environments?.split("-")[1]
                                                    ?   (filters?.environments?.split("-")[0] !== '0' && (filters?.environments?.split("-")[0].toString() === '99' ? '+9' : filters?.environments?.split("-")[0]) + " amb.")
                                                    :   (filters?.environments?.split("-")[0] !== '0' ? filters?.environments?.split("-")[0] : '') + (filters.environments.split("-")[1] !== '99' || filters.environments.split("-")[0] === '0' ? " a " + filters.environments.split("-")[1]:'') + " amb."
                                                } 
                                                <div onClick={() =>  updateFilters(setFilters,filters,'environments','')} className="btn-delete-tag cursor-pointer ms-3">×</div>
                                            </div>}
                                        </div>):''}
                                    {filters.environments_types.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'environments_types',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                    {filters.services.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'services',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                    {filters.specials.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'specials',makeCustom(item))} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                    {filters.generals.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'generals',item)} className="btn-delete-tag cursor-pointer ms-3">×</div></div>}</div>))}
                                </div>
                                <a className="btn-clear cursor-pointer"
                                onClick={() =>
                                    setFilters((filters) => ({
                                        location: [],
                                        type: [],
                                        operation: location[1],
                                        environments: '',
                                        bedrooms: "",
                                        price: {
                                        type: "",
                                        priceMin: 0,
                                        priceMax: 50000000,
                                        },
                                        surface: {
                                        type: "",
                                        surfaceMin: 0,
                                        surfaceMax: 10000,
                                        },
                                        environments_types: [],
                                        generals: [],
                                        services: [],
                                        specials: [],
                                    })) +
                                    setAuxiliarPrice({
                                        priceType:'',
                                        priceMin:'',
                                        priceMax:'',
                                    })+
                                    setAuxiliarSurface({
                                        surfaceType:'',
                                        surfaceMin:'',
                                        surfaceMax:'',
                                })
                                    }>Limpiar</a>
                            </div>
                        </div>
                        <div className="filters-group " id="operation">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-operation" aria-expanded="false" aria-controls="collapse-operation">Tipo de operación<a className="icon-arrow-down"></a></h3>
                            <div className="options collapse" id="collapse-operation">
                                <div className="row show pb-4">
                                    <label className={"option " + (checkOperation(total_tokko_filters.data.objects,1) === undefined ? 'd-none' : '')}>
                                        <input onChange={() => updateFilters(setFilters,filters,"operation", "venta")} checked={checkCurrentFilters(filters,"operation",'venta')} type="radio" id="o-venta" name="operation" value="1" />
                                        <span className="radio"></span>Venta ({totalCountVenta})
                                    </label>
                                    <label className={"option " + (checkOperation(total_tokko_filters.data.objects,2) === undefined ? 'd-none' : '')}>
                                        <input onChange={() => updateFilters(setFilters,filters,"operation", "alquiler")} checked={checkCurrentFilters(filters,"operation",'alquiler')} type="radio" id="o-alquiler" name="operation" value="2" />
                                        <span className="radio"></span>Alquiler ({(totalCountAlquiler)})
                                    </label>
                                    <label className={"option " + (checkOperation(total_tokko_filters.data.objects,3) === undefined ? 'd-none' : '')}>
                                        <input onChange={() => updateFilters(setFilters,filters,"operation", "alquiler-temporario")} checked={checkCurrentFilters(filters,"operation",'alquiler-temporario')} type="radio"  id="o-alquiler-temporario" name="operation" value="3" />
                                        <span className="radio"></span>Alquiler temporario ({(totalCountAlquilerTemp)})
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="filters-group " id="types">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0"  data-bs-toggle="collapse" data-bs-target="#collapse-types" aria-expanded="false" aria-controls="collapse-types">Tipo de propiedad <a className="icon-arrow-down"></a></h3>
                            <div className="options collapse" id="collapse-types">
                                <div className="row pb-4">
                                {total_tokko_filters.data.objects.property_types.map((item,i) => (
                                    <label className={"option " + (getTypeCount(tokko_filters.data.objects,item) === 0 ? "d-none" : '')} key={i}>
                                        <input type="checkbox" onChange={() => updateFilters(setFilters,filters,"type", {id:item.id,name:item.type})} checked={checkCurrentFilters(filters,"type",item.type)} id={"type " + item.id} name="type" value="none" />
                                        <span className="square"></span>{item.type} ({getTypeCount(tokko_filters.data.objects,item)})
                                    </label>
                                ))}
                                </div>
                            </div>
                        </div>
                        <div className="filters-group " id="location">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-location" aria-expanded="false" aria-controls="collapse-location">Ubicación <a className="icon-arrow-down"></a></h3>
                            <div className="options collapse" id="collapse-location">
                                <div className="row pb-4">
                                    {listLocationFilters.data.objects.locations.sort((a,b) => a.location_name.localeCompare(b.location_name)).map((item,i) => (
                                        <label className={"option " + (getLocationCount(listLocationFilters.data.objects,item) === 0 ? "d-none" : '')} key={i}>
                                            <input id="" onChange={() => updateFilters(setFilters,filters,"location", {id:item.location_id,name:item.location_name})} checked={checkCurrentFilters(filters,"location",item.location_name)} type="checkbox" className="radio-custom" name="location[]" value={item.location_id} />
                                            <span className="square"></span> {item.location_name}  
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="filters-group " id="environments">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-amb" aria-expanded="false" aria-controls="collapse-amb">Ambientes <a className="icon-arrow-down"></a></h3>
                            <div className="options collapse" id="collapse-amb">
                                <div className="row pb-4" >
                                    <div className="col-lg-6">
                                        <div className="d-flex ms-2">
                                            <div className={'d-block content-field not-closer px-lg-4 pe-3 pe-lg-4 ' + (!openMinEnvironment && 'close')}>
                                                <div className={"d-lg-flex dropdown-select btn btn-main no-hover not-closer me-2 "} onClick={() => setOpenMinEnvironment(!openMinEnvironment)}  >
                                                    <span className="" >
                                                        {environments.min === '' || environments.min === 0 ? 'Minimo' : (environments.min.toString() === '99' ? "+9" : environments.min) + ' amb'}
                                                    </span>                                            
                                                    <i className='icon-arrow-light not-closer'></i>
                                                </div>
                                                <div className={"w-auto not-closer float-select position-relative " }>
                                                    <div className={"not-closer flex-input px-0 " + (((!checkEnvironment(listDynamicFilters.data.objects,1)))  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",1)}  checked={environments.min.toString() === "1"} type="radio" id="amb-aux-1-min" name="roomsMin" value="1" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 1 ? 'active' : '')} htmlFor={"amb-aux-1-min"}>
                                                            <div className="custom-span not-closer"></div>1 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +(((!checkEnvironment(listDynamicFilters.data.objects,2))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",2)}  checked={environments.min.toString() === "2"} type="radio" id="amb-aux-2-min" name="roomsMin" value="2" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 2 ? 'active' : '')} htmlFor={"amb-aux-2-min"}>
                                                            <div className="custom-span not-closer"></div>2 amb
                                                        </label> 
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,3))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",3)}  checked={environments.min.toString() === "3"} type="radio" id="amb-aux-3-min" name="roomsMin" value="3" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 3 ? 'active' : '')} htmlFor={"amb-aux-3-min"}>
                                                            <div className="custom-span not-closer"></div>3 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " + (((!checkEnvironment(listDynamicFilters.data.objects,4))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",4)}  checked={environments.min.toString() === "4"} type="radio" id="amb-aux-4-min" name="roomsMin" value="4" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 4 ? 'active' : '')} htmlFor={"amb-aux-4-min"}>
                                                            <div className="custom-span not-closer"></div>4 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,5))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",5)}  checked={environments.min.toString() === "5"} type="radio" id="amb-aux-5-min" name="roomsMin" value="5" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 5 ? 'active' : '')} htmlFor={"amb-aux-5-min"}>
                                                            <div className="custom-span not-closer"></div>5 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,6))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",6)}  checked={environments.min.toString() === "6"} type="radio" id="amb-aux-6-min" name="roomsMin" value="6" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 6 ? 'active' : '')} htmlFor={"amb-aux-6-min"}>
                                                            <div className="custom-span not-closer"></div>6 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,7))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",7)}  checked={environments.min.toString() === "7"} type="radio" id="amb-aux-7-min" name="roomsMin" value="7" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 7 ? 'active' : '')} htmlFor={"amb-aux-7-min"}>
                                                            <div className="custom-span not-closer"></div>7 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,8))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",8)}  checked={environments.min.toString() === "8"} type="radio" id="amb-aux-8-min" name="roomsMin" value="8" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 8 ? 'active' : '')} htmlFor={"amb-aux-8-min"}>
                                                            <div className="custom-span not-closer"></div>8 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,9))) && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("min",9)}  checked={environments.min.toString() === "9"} type="radio" id="amb-aux-9-min" name="roomsMin" value="9" />
                                                        <label className={"not-closer " + (checkEnvironments().min === 9 ? 'active' : '')} htmlFor={"amb-aux-9-min"}>
                                                            <div className="custom-span not-closer"></div>+9 amb
                                                        </label>  
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-block content-field not-closer px-lg-4 pe-3 pe-lg-4 ' + (!openMaxEnvironment && 'close') }>
                                                {/* //Max */}
                                                <div className={"d-lg-block not-closer " } onClick={() => setOpenMaxEnvironment(!openMaxEnvironment)}> 
                                                    <span className="d-lg-flex dropdown-select btn btn-main no-hover not-closer me-2 ">
                                                    {environments.max === '' || environments.max === 0 ? 'Máximo' : (environments.max.toString() === '99' ? '+9' : environments.max.toString()) + ' amb'}
                                                        <i className='icon-arrow-light not-closer'></i>
                                                    </span>
                                                </div>
                                                <div className="w-auto not-closer float-select position-relative">
                                                    <div className={"not-closer flex-input " + ((!checkEnvironment(listDynamicFilters.data.objects,1) || environments.min > 1)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",1)}  checked={environments.max.toString() === "1"} type="radio" id="amb-aux-1-max" name="roomsMax" value="1" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 1 ? 'active' : '')} htmlFor={"amb-aux-1-max"}>
                                                            <div className="custom-span not-closer"></div>1 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,2) || environments.min > 2)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",2)}  checked={environments.max.toString() === "2"} type="radio" id="amb-aux-2-max" name="roomsMax" value="2" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 1 ? 'active' : '')} htmlFor={"amb-aux-2-max"}>
                                                            <div className="custom-span not-closer"></div>2 amb
                                                        </label> 
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,3) || environments.min > 3)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",3)}  checked={environments.max.toString() === "3"} type="radio" id="amb-aux-3-max" name="roomsMax" value="3" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 3 ? 'active' : '')} htmlFor={"amb-aux-3-max"}>
                                                            <div className="custom-span not-closer"></div>3 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,4) || environments.min > 4)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",4)}  checked={environments.max.toString() === "4"} type="radio" id="amb-aux-4-max" name="roomsMax" value="4" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 4 ? 'active' : '')} htmlFor={"amb-aux-4-max"}>
                                                            <div className="custom-span not-closer"></div>4 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,5) || environments.min > 5)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",5)}  checked={environments.max.toString() === "5"} type="radio" id="amb-aux-5-max" name="roomsMax" value="5" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 5 ? 'active' : '')} htmlFor={"amb-aux-5-max"}>
                                                            <div className="custom-span not-closer"></div>5 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " + ((!checkEnvironment(listDynamicFilters.data.objects,6) || environments.min > 6)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",6)}  checked={environments.max.toString() === "6"} type="radio" id="amb-aux-6-max" name="roomsMax" value="6" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 6 ? 'active' : '')} htmlFor={"amb-aux-6-max"}>
                                                            <div className="custom-span not-closer"></div>6 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,7) || environments.min > 7)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",7)}  checked={environments.max.toString() === "7"} type="radio" id="amb-aux-7-max" name="roomsMax" value="7" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 7 ? 'active' : '')} htmlFor={"amb-aux-7-max"}>
                                                            <div className="custom-span not-closer"></div>7 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,8) || environments.min > 8)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",8)}  checked={environments.max.toString() === "8"} type="radio" id="amb-aux-8-max" name="roomsMax" value="8" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 8 ? 'active' : '')} htmlFor={"amb-aux-8-max"}>
                                                            <div className="custom-span not-closer"></div>8 amb
                                                        </label>  
                                                    </div>
                                                    <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,9) || environments.min > 9)  && "d-none")}>
                                                        <input className="not-closer" onChange={() =>  updateEnvironment("max",9)}  checked={environments.max.toString() === "9"} type="radio" id="amb-aux-9-max" name="roomsMax" value="9" />
                                                        <label className={"not-closer " + (checkEnvironments().max === 9 ? 'active' : '')} htmlFor={"amb-aux-9-max"}>
                                                            <div className="custom-span not-closer"></div>+9 amb
                                                        </label>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Old checkbox options */}
                                {/* <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,1) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,1) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "1 Ambiente")} checked={checkCurrentFilters(filters,"environments","1 Ambiente")} type="radio" id="amb-1" name="rooms[]" value="1" />
                                    <span className="square"></span>1 Ambiente
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,2) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,2) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "2 Ambientes")} checked={checkCurrentFilters(filters,"environments", "2 Ambientes")} type="radio" id="amb-2" name="rooms[]" value="2" />
                                    <span className="square"></span>2 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,3) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,3) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "3 Ambientes")}  checked={checkCurrentFilters(filters,"environments", "3 Ambientes")} type="radio" id="amb-3" name="rooms[]" value="3" />
                                    <span className="square"></span>3 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,4) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,4) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "4 Ambientes")} checked={checkCurrentFilters(filters,"environments", "4 Ambientes")} type="radio" id="amb-4" name="rooms[]" value="4" />
                                    <span className="square"></span>4 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,5) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,5) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "5 Ambientes")}  checked={checkCurrentFilters(filters,"environments", "5 Ambientes")} type="radio" id="amb-5" name="rooms[]" value="5" />
                                    <span className="square"></span>5 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,6) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,6) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "6 Ambientes")} checked={checkCurrentFilters(filters,"environments", "6 Ambientes")} type="radio" id="amb-6" name="rooms[]" value="6" />
                                    <span className="square"></span>6 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,7) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,7) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "7 Ambientes")} checked={checkCurrentFilters(filters,"environments", "7 Ambientes")} type="radio" id="amb-7" name="rooms[]" value="7" />
                                    <span className="square"></span>7 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,8) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,8) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "8 Ambientes")} checked={checkCurrentFilters(filters,"environments", "8 Ambientes")} type="radio" id="amb-8" name="rooms[]" value="8" />
                                    <span className="square"></span>8 Ambientes
                                </label>
                                <label className={"option me-5 " + (!checkEnvironment(listDynamicFilters.data.objects,9) ? " d-none " : "") + (!checkEnvironment(listDynamicFilters.data.objects,9) ? ' d-none ' : "")}>
                                    <input onChange={() => updateFilters(setFilters,filters,"environments", "mas de 9 Ambientes")} checked={checkCurrentFilters(filters,"environments", "mas de 9 Ambientes")} type="radio" id="amb-9" name="rooms[]" value="9" />
                                    <span className="square"></span>+9 Ambientes
                                </label> */}
                            </div>
                        </div>
                        <div className="filters-group " id="age">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer  collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-age" aria-expanded="false" aria-controls="collapse-age">Antigüedad <a className="icon-arrow text-right"></a></h3>
                            <div className="options collapse" id="collapse-age">
                                <div className="row pb-4">
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "En construcción")}  checked={checkCurrentFilters(filters,"age", "En construcción")} type="radio" id="age-1" name="age" value="-1" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>En construcción
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "A estrenar")}  checked={checkCurrentFilters(filters,"age", "A estrenar")} type="radio" id="age-2" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>A estrenar
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "1 a 5 años")}  checked={checkCurrentFilters(filters,"age", "1 a 5 años")} type="radio" id="age-2" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>1 a 5 años
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "6 a 10 años")}  checked={checkCurrentFilters(filters,"age", "6 a 10 años")} type="radio" id="age-3" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>6 a 10 años
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "11 a 20 años")}  checked={checkCurrentFilters(filters,"age", "11 a 20 años")} type="radio" id="age-4" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>11 a 20 años
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "21 a 50 años")}  checked={checkCurrentFilters(filters,"age", "21 a 50 años")} type="radio" id="age-5" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>21 a 50 años
                                        </label>  
                                    </div>
                                    <div className={"col-lg-4 "}>
                                        <label className={"option me-5 " }>
                                            <input onChange={() => updateFilters(setFilters,filters,"age", "+ 50 años")}  checked={checkCurrentFilters(filters,"age", "+ 50 años")} type="radio" id="age-6" name="age" value="0" />
                                            <span className="" style={{borderRadius:"100%",overflow:"hidden"}}></span>+ 50 años
                                        </label>  
                                    </div>

                                </div>
                            </div>
                        </div>  

                        <div className="filters-group " id="price_range">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-price" aria-expanded="false" aria-controls="collapse-price">Rango de precio <a className="icon-arrow-down"></a></h3>
                            <div className="collapse" id="collapse-price">
                                <div id="moneda" className="options d-flex d-lg-block pt-3 pb-0">
                                    <label className="option me-5 d-inline">
                                        <input onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceType : "pesos"})} checked={auxiliarValuesPrice.priceType.toLowerCase() === 'pesos'} type="radio" id="peso" name="currency" value="ARS" />
                                        <span className="radio"></span>Pesos
                                    </label>
                                    <label className="option d-inline">
                                        <input onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceType : "dolares"})} checked={auxiliarValuesPrice.priceType.toLowerCase() === 'dolares'} type="radio" id="dolar" name="currency" value="USD"  />
                                        <span className="radio"></span>Dólares
                                    </label>
                                </div>
                                <div className="options px-lg-0 px-0 my-2 my-lg-0 pb-5">
                                    <div className="row">
                                        <div className={"col-12 mb-4 " + (!auxiliarValuesPrice.priceType ? ' ' : "opacity") }><span className="text-danger">Primero debes selecciónar un tipo de moneda.</span></div>
                                        <div className="col-6">
                                            <input
                                                type="text"
                                                placeholder='Desde'
                                                disabled={!auxiliarValuesPrice.priceType}
                                                onFocus={() => setHelpers({...helpers,minprice:1})}
                                                onChange={(e) => setAuxiliarPrice({...auxiliarValuesPrice, priceMin : e.target.value})}
                                                value={(auxiliarValuesPrice.priceMin)}
                                            />
                                            <div className={"cursor-pointer price-helper " + (helpers.minprice ? '' : 'd-none')}>
                                                {pricesDefault.map((price,index) => (
                                                    <div onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceMin : price}) + setHelpers({...helpers,minprice:0})} key={index} className="price-helper-item">{Intl.NumberFormat("de-DE").format(price)}</div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                        <input
                                            type="text"
                                            disabled={!auxiliarValuesPrice.priceType}
                                            placeholder='Hasta'
                                            onFocus={() => setHelpers({...helpers,maxprice:1})}
                                            onChange={(e) => setAuxiliarPrice({...auxiliarValuesPrice, priceMax : e.target.value})}
                                            value={(auxiliarValuesPrice.priceMax)}
                                        />
                                        <div className={"cursor-pointer price-helper " + (helpers.maxprice ? '' : 'd-none')}>
                                            {pricesDefault.map((price,index) => (
                                                price > auxiliarValuesPrice.priceMin ?
                                                    <div onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceMax : price}) + setHelpers({...helpers,maxprice:0})} key={index} className="price-helper-item">{Intl.NumberFormat("de-DE").format(price)}</div>
                                                :
                                                    null
                                                ))}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="btn btn-red w-100 position-relative mt-4" onClick={() => sendAuxiliar()}>Aplicar</div>
                                </div>
                            </div>
                        </div>
                        <div className="filters-group " id="surface">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapse-sup" aria-expanded="false" aria-controls="collapse-sup">Superficie <a className="icon-arrow-down"></a></h3>
                            <div className="collapse" id="collapse-sup">
                                <div id="moneda" className="options d-flex d-lg-block pt-3 pb-0">
                                    <label className="option me-5 d-inline">
                                        <input onClick={() => setAuxiliarSurface({...auxiliarValuesSurface, surfaceType : "cubierta"})} checked={auxiliarValuesSurface.surfaceType === 'cubierta'} type="radio" id="peso" name="surface_type" value="ARS" />
                                        <span className="radio"></span>Cubierta
                                    </label>
                                    <label className="option d-inline">
                                        <input onClick={() => setAuxiliarSurface({...auxiliarValuesSurface, surfaceType : "total"})} checked={auxiliarValuesSurface.surfaceType === 'total'} type="radio" id="dolar" name="surface_type" value="USD"  />
                                        <span className="radio"></span>Total
                                    </label>
                                </div>
                                <div className="options px-lg-0 px-0 my-2 m-lg-0 pb-5">
                                    <div className="row">
                                        <div className={"col-12 mb-4 " + (!auxiliarValuesSurface.surfaceType ? ' ' : "opacity") }><span className="text-danger">Primero debes selecciónar un tipo de superfie.</span></div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    placeholder='Desde'
                                                    disabled={!auxiliarValuesSurface.surfaceType}
                                                    onChange={(e) => setAuxiliarSurface({...auxiliarValuesSurface, surfaceMin : e.target.value})}
                                                    value={(auxiliarValuesSurface.surfaceMin)}
                                                />
                                            </div>
                                            <div className="col-6">
                                            <input
                                                type="text"
                                                placeholder='Hasta'
                                                disabled={!auxiliarValuesSurface.surfaceType}
                                                onChange={(e) => setAuxiliarSurface({...auxiliarValuesSurface, surfaceMax : e.target.value})}
                                                value={(auxiliarValuesSurface.surfaceMax)}
                                            />
                                            </div>
                                    </div>
                                    <div className="btn btn-red w-100 mt-4 position-relative" onClick={() => sendAuxiliar()}>Aplicar</div>
                                </div>
                            </div>
                        </div>
                        <div className="filters-group " id="types_environments">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapsetypes" aria-expanded="false" aria-controls="collapsetypes">Tipos de ambientes <a className="icon-arrow-down"></a></h3>
                                <div className="options pb-lg-5 collapse  " id="collapsetypes">
                                    <div className="row pb-4">
                                        {getEnvironment(listDynamicFilters.data.objects).map((environment, index) => (
                                            <label className={"option " + (getTagCount(tokko_filters.data.objects,environment) === 0 ? "d-none" : '')} key={index}>
                                                <input onChange={() => updateFilters(setFilters,filters,"environments_types", {id:environment.tag_id,name:environment.tag_name})} checked={checkCurrentFilters(filters,"environments_types", {id:environment.tag_id,name:environment.tag_name})} type="checkbox" id={'roomtype-' + environment.tag_id} name="roomtypes[]" value={environment} />
                                                <span className="square"></span>{environment.tag_name}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                        </div>
                        <div className="filters-group " id="generals">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-4 m-0" data-bs-toggle="collapse" data-bs-target="#collapseamenities" aria-expanded="false" aria-controls="collapseamenities">Generales <a className="icon-arrow-down"></a></h3>
                            <div className="options pb-lg-5 collapse  " id="collapseamenities">
                                <div className="row pb-4">
                                    {getGenerals(listDynamicFilters.data.objects).map((generals, index) => (
                                        <label className={"option " + (getTagCount(tokko_filters.data.objects,generals) === 0 ? "d-none" : '')} key={index}>
                                            <input onChange={() => updateFilters(setFilters,filters,"generals", {id:generals.tag_id,name:generals.tag_name})} checked={checkCurrentFilters(filters,"generals", {id:generals.tag_id,name:generals.tag_name})} type="checkbox" id={"amenities-"+generals.tag_id} name="amenities[]" value={generals} />
                                            <span className="square"></span>{generals.tag_name}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="filters-group py-2" id="services">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-3" data-bs-toggle="collapse" data-bs-target="#collapseservice" aria-expanded="false" aria-controls="collapseservice">Servicios <a className="icon-arrow-down"></a></h3>
                            <div className="options pb-lg-5 collapse  " id="collapseservice">
                                <div className="row pb-4 pb-lg-0">
                                    {getServices(listDynamicFilters.data.objects).map((services, index) => (
                                        <label className={"option " + (getTagCount(tokko_filters.data.objects,services) === 0 ? "d-none" : '')} key={index}>
                                            <input type="checkbox" onChange={() => updateFilters(setFilters,filters,"services", {id:services.tag_id,name:services.tag_name})} checked={checkCurrentFilters(filters,"services", {id:services.tag_id,name:services.tag_name})} id={"service-" + services.tag_id} name="services[]" value={services} />
                                            <span className="square"></span>{services.tag_name}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div> */}
                        {/* <div className={"filters-group py-2 " + (getCustoms(custom_tags).filter(element => element.group_name === 'Especiales' && element.count > 0) && 'd-none')} id="custom_tags">
                            <h3 className="d-flex align-items-center justify-content-between cursor-pointer collapsed py-3" data-bs-toggle="collapse" data-bs-target="#collapsecustoms" aria-expanded="false" aria-controls="collapsecustoms">Etiquetas especiales <a className="icon-arrow-down"></a></h3>
                            <div className="options pb-lg-5 collapse  " id="collapsecustoms">
                                <div className="row pb-4 pb-lg-0">
                                    {getCustoms(custom_tags)?.map((custom, index) => (
                                        <div className={"col-lg-4 " + (custom.count === 0 || custom?.group_name?.toLowerCase() !== 'especiales' ? "d-none" : '')}>
                                            <label className={"option " }>
                                                <input type="checkbox" onChange={() => updateFilters(setFilters,filters, "specials", makeCustom(custom))} checked={checkCurrentFilters(filters,"specials", makeCustom(custom))} id={"custom-" + custom.id} name="specials[]" value={custom} />
                                                <span className="square"></span>{custom.name} ({custom.count})
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> */}
                        {/* <input disabled={totalCount === 0} onClick={() => setMenu(false)} className="mx-auto d-block mt-5 btn btn-primary" value={(totalCount > 0 ? 'Ver ' : '') + totalCount + " resultados"} /> */}
                    </form>
                :   <div className="filters-container filter-form skeleton" />
            }

        </aside>
    )
}

export default connect(state => ({
    _filters: state.properties.filters,
    offset: state.properties.offset,
    tokko_filters: state.properties.listFilters,
    polygonCoordinates: state.properties.polygonCoordinates,
    total_tokko_filters: state.properties.totalListFilters,
    listLocationFilters:state.properties.listLocationFilters,
    loading_locations: state.properties.loading_locations,
    listDynamicFilters: state.properties.listDynamicFilters,
    loading_dynamic:state.properties.loading_dynamic,
    totalCountAlquiler: state.properties.totalCountAlquiler,
    totalCountVenta: state.properties.totalCountVenta,
    totalCountAlquilerTemporario:state.properties.totalCountAlquilerTemporario,
    totalCountAlquilerTemp:state.properties.totalCountAlquilerTemp,
    orders: state.properties.orders,
    api_key: state.settings.keys.tokko,
    totalCount: state.properties.totalCount,
    custom_tags: state.properties.custom_tags,
    drawnPolygon: state.properties.drawnPolygon,
    allLocations:state.properties.allLocationFilters,
    loading_Alllocatons:state.properties.loading_Alllocatons,
    loading_total_filters:state.properties.loading_total_filters
}),null)(Filter);
