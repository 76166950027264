import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel3';
import React, {useState, useRef, useEffect} from 'react'

import { connect } from 'react-redux'
import { getFavoritesAction,clearFavoritesAction, deleteFavoriteAction,changeSelectedAction, updaterFavoritesAction } from '../redux/propertiesDucks'
import {addMarker} from '../helpers/helper.map'

//Helpers
import { getCover,getTextShort ,getPreviewSlider, getQuality } from '../helpers/helper.rendering';
import { addFavorites, getfavorites, checkFavorite } from '../helpers/helper.favorites';
import { 
    getOperations,
    getType,
    getEnvironment,
    getSurface,
    getLocation,
    getFakeAddres,
    getPrices,
    makeLink,
    amenitiesTags,
    getExpenses
    } from '../helpers/helper.properties';

import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import { getTitle } from '../helpers/helper.developments';
import { useLocation } from "@reach/router";
import Slider from "react-slick";

const settingsSlick = {
    dots: false,
    infinite: true,
    speed: 500,
    fade:false,
    slidesToShow: 1,
    slidesToScroll: 1
  };
const Card = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const { property } = props
    const { settings } = props
    const { dispatch } = props
    const { skeleton } = props
    const { handlerClick } = props
    const { setZoom, map } = props
    const { filters } = props
    const { allPrice } = props
    const { operationPrice } = props



    const opacity = props?.opacity

    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const slider_gallery = useRef()
    const sliderCard = useRef()
    const [positionSliderCard,setPositionSliderCard] = useState(1)
    const toastCheck = (id,fake_address) => {
        if(!checkFavorite(id,"prop",settings.short_name))
            return toast.success(fake_address + " agregada a tus favoritos") 
        else
            return toast.success(fake_address + " eliminada de tus favoritos")
    }

    const addHoverMap = (property) => {
        // $('.marker-' + id).addClass(' hover-marker');
        $('#marker-' + property.id).addClass(' hover-marker');
        $('#marker-' + property.development?.id).addClass(' hover-marker');
    }

    const removeHoverMap = (id) => {
        // $('.marker-' + id).removeClass(' hover-marker');
        $('#marker-' + property.id).removeClass(' hover-marker');
        $('#marker-' + property.development?.id).removeClass(' hover-marker');
    }

    const renderCardBody = () => {
        return props?.grid_map ? renderCardGrid() : renderDefaultCard()
    }

    const renderCardGrid = () => {
        return <div class="card-body grid-card-body">
                <div class="card-location-section d-flex justify-content-between align-items-center mb-2">
                    <span class="location-info">
                        {getType(property)}
                        {getEnvironment(property) ?    " | "   + getEnvironment(property) +  " ambiente" + (getEnvironment(property) > 1 ? 's' : ''): ''}   
                        {/* {getSurface(property) ?  " | " + getSurface(property) : ''   } */}
                    </span>
                    <span onClick={() => 
                        dispatch(updaterFavoritesAction()) +
                        toastCheck(property.id, property.fake_address) + 
                        addFavorites(property.id,"prop",settings.short_name) + 
                        setListFavorites(getfavorites("prop",settings.short_name)) + 
                        dispatch(deleteFavoriteAction(property.id))}
                        className={'fav-btn ' + (listFavorites.find(element => element.toString() === property.id.toString()) ? 'active' : '')} >
                        <i class={"icon-favoritos-heart"+(listFavorites.find(element => element.toString() === property.id.toString()) ? '-solid' : '')}></i>
                    </span>
                </div>
                <div className="card-header-section d-flex justify-content-between w-100">
                    <div class="card-title-section">
                        <a className="nostyle-link">
                            <h2 class="card-title">{getLocation(property)}</h2>
                            <span class="card-subtitle">{((screen.width < 1700 && getFakeAddres(property).length > 40) ? getFakeAddres(property).slice(0,45) + '...' : getFakeAddres(property))}</span>
                        </a>
                    </div>
                </div>
                <div className={`card-tags-section d-flex justify-content${checkAmenities() ? "-start" : "-start"}`}>
                    {getSurface(property) &&
                        <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                            <i className="icon-superficie-circular"></i>
                            <span className="text-nowrap">{getSurface(property) || "-"}</span>
                        </div>
                    }
                    { amenitiesTags(property)?.suite !== '00'
                        && <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                            <i className="icon-dormitorio-circular"></i>
                            <span>{amenitiesTags(property)?.suite}</span>
                        </div> }
                    { amenitiesTags(property)?.bathroom !== '00'
                        && <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                            <i className="icon-banios-circular"></i>
                            <span>{amenitiesTags(property)?.bathroom}</span>
                        </div> }
                    { amenitiesTags(property)?.toilet !== '00'
                        && <div class="tag d-flex flex-column justify-content-center align-items-center">
                            <i className="icon-toilette-circular"></i>
                            <span>{amenitiesTags(property)?.toilet}</span>
                        </div> }
                    { amenitiesTags(property)?.garage !== '00'
                        && <div class="tag d-flex flex-column justify-content-center align-items-center">
                            <i className="icon-cocheras-circular"></i>
                            <span>{amenitiesTags(property)?.garage}</span>
                        </div> }
                </div>
                <div class="card-price-section d-flex w-100">
                    <div className="price-content d-flex gap-3">
                        {getPrices(property).map((price, index) => (
                            (price.type.toLowerCase().replace(" ", "-") === filters?.operation?.toLowerCase() || allPrice) &&
                            <div className={`d-flex ${property?.expenses > 0 ? 'flex-column': ''}`}>
                                <p class={"mt-3 prop-price " + price.type + (property?.expenses <= 0 ? " noexp" : "") + (property?.expenses > 0 ? 'me-4': '')} key={index}>
                                    {price.currency} 
                                    {price.price > 0 ? Intl.NumberFormat("de-DE").format(price.price) : price.price}
                                </p>
                                {property?.expenses > 0 && (price.type.toLowerCase() === 'alquiler' || price.type.toLowerCase() === 'alquiler temporario') 
                                    ? <p class="prop-exp">
                                        EXP: ARS {Intl.NumberFormat("de-DE").format(getExpenses(property))}
                                    </p> : ''}
                            </div>
                        ))}
                    </div>
                    <a target={"_blank"} href={makeLink(property)} className={`prop-link ${getPrices(property).length > 1 ? 'align-self-center': ''}`}>Ver +</a>
                </div>
            </div>
    }

    const renderDefaultCard = () => {
        return <div class={"card-body" + (checkPages() ? ' card-operation-body' : '')}>
            <div onClick={() => window.open(makeLink(property))} className="clicker"></div>
            <div class="card-location-section d-flex justify-content-between align-items-center">
                <span class="location-info">
                    {getType(property)}
                    {getEnvironment(property) ?    " | "   + getEnvironment(property) +  " ambiente" + (getEnvironment(property) > 1 ? 's' : ''): ''}
                    {/* {getSurface(property) ?  " | " + getSurface(property) : ''   } */}
                </span>
                <span onClick={() => 
                    dispatch(updaterFavoritesAction()) +
                    toastCheck(property.id, property.fake_address) + 
                    addFavorites(property.id,"prop",settings.short_name) + 
                    setListFavorites(getfavorites("prop",settings.short_name)) + 
                    dispatch(deleteFavoriteAction(property.id))}
                    className={'fav-btn ' + (listFavorites.find(element => element.toString() === property.id.toString()) ? 'active' : '')} >
                    <i class={"icon-favoritos-heart"+(listFavorites.find(element => element.toString() === property.id.toString()) ? '-solid' : '')}></i></span>
            </div>
            <div class="card-title-section">
                <a target={"_blank"} href={makeLink(property)} className="nostyle-link">
                    <h2 class="card-title">{getLocation(property)}</h2>
                    {/* <span class="card-subtitle">{getFakeAddres(property)}</span> */}
                    <span class="card-subtitle">{getTextShort(getFakeAddres(property), (checkPages() ? 40 : 40))}</span>

                </a>
            </div>
            <div class="card-description-section">
                <span class="desc">{getTextShort(getTitle(property), (checkPages() ? 40 : 40))}</span>
            </div>
            <hr />
            <div className={`card-tags-section d-flex flex-wrap justify-content${checkAmenities() ? "-start" : "-start"}`}>
                {getSurface(property) && 
                    <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                        <i className="icon-superficie-circular"></i>
                        <span className="text-nowrap">{getSurface(property) || "-"}</span>
                    </div>
                }
                { amenitiesTags(property)?.suite !== '00'
                    ? <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                        <i className="icon-dormitorio-circular"></i>
                        <span>{amenitiesTags(property)?.suite}</span>
                    </div> : '' }
                { amenitiesTags(property)?.bathroom !== '00'
                    ? <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                        <i className="icon-banios-circular"></i>
                        <span>{amenitiesTags(property)?.bathroom}</span>
                    </div> : '' }
                { amenitiesTags(property)?.toilet !== '00' 
                    ? <div class="tag d-flex flex-column justify-content-center align-items-center">
                        <i className="icon-toilette-circular"></i>
                        <span>{amenitiesTags(property)?.toilet}</span>
                    </div> : '' }
                { amenitiesTags(property)?.garage !== '00'
                    ? <div class={`tag d-flex flex-column justify-content-center align-items-center`}>
                        <i className="icon-cocheras-circular"></i>
                        <span>{amenitiesTags(property)?.garage}</span>
                    </div> : '' }
            </div>
            <div class="card-price-section d-flex justify-content-between align-items-center mb-0    ">
                <div className="d-flex flex-column">
                    {getPrices(property).map((price, index) => (

                        (price.type.toLowerCase().replace(" ", "-") === filters?.operation?.toLowerCase()
                         || allPrice
                         || operationPrice?.toLowerCase().replace(" ", "-") === price.type.toLowerCase().replace(" ", "-") ) &&
                        <p class={"prop-price " + price.type + (property?.expenses <= 0 ? " noexp" : "")} key={index}>
                            {price.currency} 
                            {price.price > 0 ? Intl.NumberFormat("de-DE").format(price.price) : price.price}
                            {property?.expenses > 0 && (price.type.toLowerCase() === 'alquiler' || price.type.toLowerCase() === 'alquiler temporario') 
                            ? <span class="prop-exp">
                                EXP: ARS {Intl.NumberFormat("de-DE").format(getExpenses(property))}
                            </span> : ''}
                        </p>
                    ))}
                </div>
                <a target={"_blank"} href={makeLink(property)} className="prop-link">Ver +</a>
            </div>
        </div>
    }

    const location = useLocation()
    const checkPages = () => {
        const isAlquilerPage = location.pathname.includes("alquiler")
        const isVentaPage = location.pathname.includes("venta")
        return (isAlquilerPage || isVentaPage) ? true : false;
    }

    const checkAmenities = () => {
        let room = amenitiesTags(property)?.room !== '00';
        let bathroom = amenitiesTags(property)?.bathroom !== '00';
        let suite = amenitiesTags(property)?.suite !== '00';
        let garage = amenitiesTags(property)?.garage !== '00';
        let toilet = amenitiesTags(property)?.toilet !== '00';
        let am = [room, bathroom, suite, garage,toilet]
        // return !!((am[0] && am[1]) || (am[0] && am[1] && am[2]))
        return (am[0] && am[1] && am[2] && am[3] & am[4])
    }
    return property ? (
        <div className={"property-wrapper "}>
            <div 
                onMouseEnter={() => addHoverMap(property)}
                onMouseLeave={() => removeHoverMap(property)} 
                id={"card-grid-"+ (props.grid_map && property.id)}
                onClick={() => (
                    addMarker(property.id) +
                    (props.grid_map && dispatch(changeSelectedAction(property.id))) +
                    handlerClick?.(property.geo_lat,property.geo_long,map) + setZoom?.(17))
                }        
                class={"card prop-card " + 
                    (props.inMap ? 'map-card ' : '') + // si está en el mapa, se muestra como una carta del mapa
                    (opacity ? 'opacity-card ' : '') + // si o está seleccionada la carta, se muestra con opacidad
                    (props.grid_map ? ' grid-card  ' : '') + // si está en el grid-map, se muestra como una carta del grid-map
                    + (true ? 'skeleton ' : '')} // si está en carga, se muestra como una carta de skeleton
                >
                <div class={"card-image " + (props.grid_map && "grid_map_image") + (skeleton ? 'skeleton' : '') + (checkPages() ? ' card-operation-image' : '')}>
                    {props.basic ? 
                        props.grid_map ? 
                            <a>
                                <img src={getQuality(getCover(property.photos)) } className='notScale' alt={realEstate?.name} />
                            </a>
                            :
                            <a target="_blank" href={makeLink(property)}>
                                <img src={getQuality(getCover(property.photos)) } className={'notScale '} alt={realEstate?.name} />
                            </a>
                    :
                        <>
                            <Slider afterChange={(e) => setPositionSliderCard(e+1)} ref={sliderCard} {...settingsSlick}>
                                {/* className="overflow-hidden card-carousel"> */}
                                    {(getPreviewSlider(property.photos)).map((photo, index) => (
                                        <a target={!props.grid_map && "_blank"} href={!props.grid_map && makeLink(property)}>
                                            {<img src={index === 0 ?  getQuality(photo) : getQuality(photo)} className='notScale' alt={realEstate?.name} />}
                                        </a>   
                                    ))}
                            </Slider>
                            { getPreviewSlider(property.photos).length > 1 ?
                            <>
                                <div className={"arrows-owl-isq zindex-1" + (getPreviewSlider(property.photos)?.length === 1 || positionSliderCard === 1 ? ' d-none' : '')}>
                                    <div className="arrow" onClick={() => sliderCard.current.slickPrev()}> <i></i> <i></i></div>
                                </div>
                                <div className={"arrows-owl-der zindex-1" + (getPreviewSlider(property.photos)?.length === 1 || positionSliderCard === getPreviewSlider(property.photos)?.length ? ' d-none' : '')}>
                                    <div className="arrow" onClick={() => sliderCard.current.slickNext()}> <i></i> <i></i> </div>
                                </div>
                            </>
                            : '' }
                           
                        </>
                    }
                    {
                        property && 
                            (<div className="grid-prop-wrap position-relative d-flex justify-content-between">
                               
                            </div>)
                    }
                    <div data-bs-dismiss="modal"  onClick={() => dispatch(changeSelectedAction(0))} className="close-card"><i></i><i></i></div>
                </div>
                { renderCardBody() }
            </div>
        </div>

    ): <div className="card prop-card skeleton"></div>    
}

export default connect(state => ({
    settings: state.settings,
    selected: state.properties.selected,
    filters: state.properties.filters,
}),null)(Card);