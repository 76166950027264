import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
// import "./App.css";
import { connect } from 'react-redux';
import Icon from "../../images/map-icon.png"
import Card_Property from '../property-card'
import Card_Development from '../development-card'
import { graphql, useStaticQuery } from 'gatsby';
import mapStyles from '../../components/mapStyles.json';
import { getPrices } from "../../helpers/helper.properties";
import { getPriceForMarker } from "../../helpers/helper.rendering";
import {getType,getOperationView, textTypes} from '../../helpers/helper.filters'
import { deleteSharp, handlerClick } from "../../helpers/helper.map"
import Paginate from '../paginate'
import ContentLoader from 'react-content-loader'
import $ from 'jquery';
import { useLocation } from '@reach/router';
import PENCIL from '../../images/pencil.png'
import Loader from "../LoaderMap";
import TrashSvg from "../../images/svg/trash.svg"

import {addMarker, checkMarker, getMarkers} from "../../helpers/helper.map";
import {redux_updateOrders, REDUX_UPDATE_COORDINATES, getPropertiesAction,changeSelectedAction,changeOffsetPropertiesAction, REDUX_UPDATE_POLYGON_COORDINATES, REDUX_UPDATE_DRAWN_POLYGON} from '../../redux/propertiesDucks'
import Filters from "./filters";

function changeFormat(value){
  if(value){
    var convert = value.toString()
    return convert.replace(',','.')
  }
}

const Marker = ({ children }) => children;

function getMap(props) {

  const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              keys{
                google_map
              }
          }
  }`)

  const {properties,filters, loading, dispatch, coordinates, totalCount, drawnPolygon, selected, viewId, menu, setMenu, polygonCoordinates} = props

  const mapOptions = {
    styles: mapStyles,
    zoomControl: true,
    // mapTypeControl: true,
    // scaleControl: true,
    fullscreenControl: false,
    scrollwheel:true,
    // overviewMapControl: true,
    // rotateControl: true
  };

  const [mapBorder,setMapBorder] = useState()
  const [coordinatesCheck,setCoordinatesCheck] = useState()
  const [sidebar,setSidebar] = useState(true)

  const [nodeElement,setNode] = useState(false)

  const location = useLocation()

  const [scrolling,setScrolling] = useState(false)

  const [points,setPoints] = useState([])
  const [maxPropertiesLoaded,setMaxPropertiesLoaded] = useState(50)
  const [limitedProperties,setLimitedProperties] = useState([])
  const [clickedProperties,setClickedProperties] = useState([])

  const mapRef = useRef();
  const google = useRef();
  const [bounds, setBounds] = useState(null);
  const [center,setCenter] = useState({ lat:  -34.5275818, lng:  -58.5052437})
  const [notCenter,setNotCenter] = useState(false)
  const [zoom, setZoom] = useState(11);
  const [drawing,setDrawing] = useState(false)
  const [barControllMap, setBarControllMap] = useState(null) //null - polygon - pencil
  const [shape, setShape] = useState()

  const [orders,setOrders] = useState({order:'desc',order_by:'id'})
  const selectorOrder = useRef()


  //Variable para abrir card
  const [activeMarker, setActiveMarker] = useState(null);

  const checkAndSetActive = (id) => {
    if(activeMarker === id){
      setActiveMarker(0)
      return null
    }
    setActiveMarker(id)
    return null
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 }
  });

  const searchUnitiesInResults = (id) => {
    let result = properties.filter(property => property.development?.id === id)
    return result
  }

  const handleGoogleMapApi = (map, google) => {
    if(google.drawing){
      const drawingManager =  new google.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        drawingControlOptions: {
          position: google.ControlPosition.TOP_LEFT,
          drawingModes: [
            google.drawing.OverlayType.POLYGON,
          ],
        },
      });

      google.event.addListener(drawingManager, 'overlaycomplete', function(event) { //Listener para cuando se hace click en el marcador, se borra el marcador
        var element = event.overlay;
        let polygon = []
        dispatch(REDUX_UPDATE_DRAWN_POLYGON(element)) // Esto es una prueba.
        if(shape !== undefined){
          shape.setMap(null)
        }
        setShape(element)
        if (event.type != google.drawing.OverlayType.MARKER) {
          // drawingManager.setDrawingMode(null);
          // drawingManager.setOptions({
          //   drawingControl: false
          // });
          setDrawing(true)
        }
        //Obtengo las coordenadas del polygon
        Object.values(element.latLngs.getArray()[0])[0].map((point,index) => (
          polygon.push({lat:point.toJSON().lat,lng:point.toJSON().lng})
        ))
        dispatch(REDUX_UPDATE_POLYGON_COORDINATES(polygon))
        drawingManager.setDrawingMode(null)
      });

      // google.event.addDomListener(map.getDiv(),'mousedown',function(e){
      //   drawFreeHand()
      // });

      google.event.addListener(map, "dblclick", (e) => {
        mapRef?.current?.panTo(e.latLng);
        setZoom(mapRef.current.zoom + 1)
      })

      //Función para eliminar el polygon
      google.event.addDomListener(document.getElementById('clean'),'click',function(e){
        const newPolygonCoordinates = []
        dispatch(REDUX_UPDATE_POLYGON_COORDINATES(newPolygonCoordinates))
        drawingManager.setDrawingMode(null)
        setBarControllMap(null)
        setDrawing(false)
        enable()
      }
      )


      //Función para eliminar el polygon
      // google.event.addDomListener(document.getElementById('clean'),'click',function(e){
      //   const newPolygonCoordinates = []
      //   dispatch(REDUX_UPDATE_POLYGON_COORDINATES(newPolygonCoordinates))
      //   drawingManager.setDrawingMode(null)
      //   setDrawing(false)
      //   enable()
      // })
      google.event.addDomListener(document.getElementById('button-polygon'),'click',function(e){
        drawingManager.setDrawingMode(google.drawing.OverlayType.POLYGON);
        setDrawing(false)
        enable()
      })
      google.event.addDomListener(document.getElementById('back-hand_'),'click',function(e){
        drawingManager.setDrawingMode(null);
        setDrawing(false)
        enable()
      })
      google.event.addDomListener(document.getElementById('back-hand'),'click',function(e){
        drawingManager.setDrawingMode(null);
        setDrawing(false)
        enable()
      })
      drawingManager.setMap(map);
    }

  }

  const handleSearchProperties = (isRectangle = false) => {
    if(mapBorder !== coordinatesCheck){
      setNotCenter(true)
      dispatch(changeOffsetPropertiesAction(0))
      if(isRectangle){
        setCoordinatesCheck(mapBorder)
        dispatch(REDUX_UPDATE_COORDINATES(mapBorder))
      }
      dispatch(getPropertiesAction())
    }
  }

  function disable(){
    mapRef.current.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false
    });
  }

  function enable(){
    mapRef.current.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    });
  }

  const drawAction = (e) => {
    e.preventDefault();
    disable()
    setDrawing(true)
 
    if(screen.width < 992){
      google.current.event.addDomListener(mapRef.current.getDiv(),'touchstart',function(e){
        drawFreeHand()
      });
    }
    else{
      google.current.event.addDomListener(mapRef.current.getDiv(),'mousedown',function(e){
        drawFreeHand()
      });
    }
  };

  function drawFreeHand()
  {
    //the polygon
    let poly=new google.current.Polyline({map:mapRef.current,clickable:false});

    //move-listener
    var move=google.current.event.addListener(mapRef.current,'mousemove',function(e){
        poly.getPath().push(e.latLng);
    });

      //mouseup-listener
        google.current.event.addListenerOnce(mapRef.current,'mouseup',function(e){
            google.current.event.removeListener(move);
            var path=poly.getPath();
            if(path.length > 0){

              poly.setMap(null);
              poly=new google.current.Polygon({map:mapRef.current,path:path});
              setShape(poly);
              dispatch(REDUX_UPDATE_DRAWN_POLYGON(poly));
              let polygon = []
              Object.values(poly.latLngs.getArray()[0])[0].map((point,index) => (
                polygon.push({lat:point.toJSON().lat,lng:point.toJSON().lng})
              ))
              if(screen.width < 992){
                google.current.event.clearListeners(mapRef.current.getDiv(), 'touchstart');
              }
              else{
                google.current.event.clearListeners(mapRef.current.getDiv(), 'mousedown');
              }
              dispatch(REDUX_UPDATE_POLYGON_COORDINATES(polygon))
              handleSearchProperties()
              enable()
              setDrawing(false)
            }
        });
    // }


    
  }

  const toggleFilterBar = () => {
    $(".sumary-filter").toggleClass("m-offset");
  }

  function traductorSelect(){
    switch(selectorOrder.current.value){
      case '0': setOrders({order:'desc',order_by:'price'}); break;
      case '1': setOrders({order:'asc',order_by:'price'}); break;
      case '2': setOrders({order:'desc',order_by:'id'}); break;
      case '3': setOrders({order:'asc',order_by:'id'}); break;
      case '4': setOrders({order:'desc',order_by:'total_surface'}); break;
      case '5': setOrders({order:'asc',order_by:'total_surface'}); break;
    }
  }

  const centerMap = () => {
    if(polygonCoordinates.length === 0 && points){
    // if(points.length > 0){
      if(google.current && points){
        var boundes = new google.current.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
          var marker = new google.current.Marker({
            position: new google.current.LatLng(points[i].geometry.coordinates[1], points[i].geometry.coordinates[0]),
            map: mapRef.current,
            visible: false
          });
          boundes.extend(marker.getPosition());
        }
          mapRef.current.fitBounds(boundes);
      }
    }
    // if(polygonCoordinates.length > 0 && points.length > 0){
    //   setZoom(zoom - 1)
    // }
  }

  const cleanerPolygon = () => {
    shape?.setMap(null)
    setShape(undefined)
    if(drawnPolygon){
      deleteSharp(drawnPolygon,dispatch,REDUX_UPDATE_DRAWN_POLYGON)
    }
    setCoordinatesCheck(undefined) // Elimino filtro por area local
    dispatch(REDUX_UPDATE_COORDINATES([])) // Elimino filtro por area global
    dispatch(REDUX_UPDATE_POLYGON_COORDINATES([]))
    dispatch(getPropertiesAction())
    // drawnPolygon.setMap(null)
    // dispatch(REDUX_UPDATE_DRAWN_POLYGON(null))
}
  useEffect(() => {
  },[mapBorder])

  useEffect(() => {
    dispatch(changeSelectedAction(activeMarker))
  },[activeMarker])

  useEffect(() => {
    if(selected && (!properties.find(element => element.id === selected)?.development?.id > 0 || !properties.find(element => element.id === selected)?.development?.display_on_web)){
      const sideBarElement = document.getElementById('sidebarElement'); // get id of sidebar
      const cardSelectedElement =$('#card-grid-'+selected).last();  // get id of card
      var offsetCard = cardSelectedElement?.position()?.top; // get offset of card
      setScrolling(true) // i say that app is scrolling
      sideBarElement.scrollTo({top: (sideBarElement.scrollTop + offsetCard - 300) , behavior: 'smooth'}); // scroll to card
      setTimeout(function(){  // i wait for the scroll to finish
        setScrolling(false)
      }, 1000);
    }
    if(selected === 0){
      setActiveMarker(0)
      if( viewId !== 1 && zoom > 13 ){
        setZoom(13)
      }
      // handlerClick(-34.565666, -59.1141052, mapRef)
    }
  },[selected])

  useEffect(() => {
    let points = []
    properties.map(property => ((
      property.development ?
        <>
          {points.find((element) => element.property.development?.id === property.development?.id && property.development?.display_on_web) ? null :
            points.push({
              type: "Feature",
              property:property,
              properties: { cluster: false,
                propId: property.id,
              address: property.address },
              geometry: {
                type: "Point",
                coordinates: [
                  parseFloat(changeFormat(property.geo_long)),
                  parseFloat(changeFormat(property.geo_lat))
                ]
              }
            })
          }
        </>
        :
          points.find((element) => property.geo_lat.toString().includes(element.geometry.coordinates[1].toString()) || property.geo_long.toString().includes(element.geometry.coordinates[0].toString())) ? null :
          points.push({
            type: "Feature",
            property:property,
            properties: { cluster: false,
              propId: property.id,
            address: property.address },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(changeFormat(property.geo_long)),
                parseFloat(changeFormat(property.geo_lat))
              ]
            }
        })
    )));
    setPoints(points)
    const sideBarElement = document.getElementById('sidebarElement'); // get id of sidebar
    sideBarElement.scrollTo({top: (0) , behavior: 'smooth'}); // scroll to card
  },[properties])

  useEffect(() => {

    if(viewId !== 2){
      dispatch(redux_updateOrders(orders))
      const sideBarElement = document.getElementById('sidebarElement'); // get id of sidebar
      sideBarElement.scrollTo({top: (0) , behavior: 'smooth'}); // scroll to card
    }
  },[orders])

  useEffect(() => {
    if(!notCenter){
      centerMap()
    }
    setNotCenter(false)
  },[points])

  useEffect(() => {
    if(
      filters.environments?.length == 0 &&
      filters.environments?.length == 0 &&
      filters.environments_types.length == 0 &&
      filters.generals.length == 0 &&
      filters.location.length == 0 &&
      filters.price.type == '' &&
      filters.services.length == 0 &&
      filters.specials.length == 0 &&
      filters.surface.type == '' &&
      filters.type.length == 0
    ){
      if(zoom > 10){
        // setZoom(zoom === 13 ? 14 : 13)
      }

    }

  },[filters])

  useEffect(() => {
  },[limitedProperties])

  useEffect(() => {
    if(screen.width < 992 && (barControllMap === 'pencil' || barControllMap === 'polygon')){
      document.body.classList.add('overflow-pencil');
    }
    else{
      document.body.classList.remove('overflow-pencil');
    }
  },[barControllMap])

  useEffect(() => {
    // $(".gmnoprint").css("left",parseInt($(".gmnoprint").css("left")?.toString()?.replace("px",'')) - (parseInt($(".control-side").css("width")?.toString()?.replace("px",''))))
    // const left_postion = parseInt($(".gmnoprint").css("left")?.toString()?.replace("px",'')) + parseInt($(".gmnoprint").css("width")?.toString()?.replace("px",''))
    // $(".control-side").css("left",left_postion + 5)
    // $(".control-side").css("top",5)
  })

  const openModal = (boolean) => {
    if(boolean === true){
      // $('#exampleModal')[0].modal('toggle');
    }
  }

  // $("#button-polygon").click(function() {
  //   const node = document.querySelectorAll("div");
  //   for(var i = 0; i < node.length; i++){
  //       if(node[i].style.cursor === 'url("https://maps.gstatic.com/mapfiles/crosshair.cur") 7 7, crosshair'){
  //         node[i].style.cursor = "url(/cursor.svg), auto"
  //       }
  //   }
  // });

  const textUpper = (text) => text?.replace(text[0], text[0]?.toUpperCase())

  const getLimit = (properties) => {
    const response =  []
    if(properties.length > maxPropertiesLoaded){
      for(let i = 0; i < properties.length; i++){
        if(i < maxPropertiesLoaded){
          response.push(properties[i])
        }
      }
      // for (const clicked of clickedProperties) {
      //   if(response.filter(element => element.id === clicked.id)?.length === 0){
      //     response.push(clicked)
      //   }
      // }
      return response
    }
    return properties;
  }

  // $(".map-google").mousemove(function() {
  //     const node = document.querySelectorAll("div");
  //     for(var i = 0; i < node.length; i++){
  //       if(node[i].style.cursor === 'url("https://maps.gstatic.com/mapfiles/crosshair.cur") 7 7, crosshair'){
  //         node[i].style.cursor = "url(/cursor.svg), auto"
  //       }
  //     }
  // });

  return realEstate.keys.google_map !== '' ?  (
    <div className={"d-flex wraper-map-view "}>
      <div id="grid-map" className={"position-relative " + (viewId !== 0 ? 'not-visible' : '')}>
        <div onScroll={() => selected !== 0 && !scrolling && dispatch(changeSelectedAction(0))} id="sidebarElement" className={"sidebar-properties " + (sidebar ? '' : 'close-sidebar')}>
          {/* <div onClick={() => setSidebar(!sidebar) + toggleFilterBar()} className="open-handle">{"<"}</div> */}
          <div className="sidebar-properties-header pt-2 d-flex">
            <div class="section-title">
              {loading && <h1>Cargando propiedades..</h1>}
              {!loading &&
                  <h1 className="">{loading ? 'Cargando' : totalCount} {getType(filters)?.length > 0 ? textTypes(getType(filters)) + " en " : 'Propiedades en'}{' '}
                  <span className="">{" "  + textUpper(getOperationView(filters))}</span></h1>
              }
              {/* <h5>{loading ? 'Cargando' : totalCount} resultados totales de búsqueda. </h5> */}
            </div>
            <div class={"justify-content-end d-flex align-items-center mb-4 mb-lg-0 me-3 " + (properties.length === 0 && !loading && ' d-none')} id="orderBy">
              Ordenar por:
              <div class="content-select">
              <select ref={selectorOrder} onChange={() => traductorSelect()} className="text-end" name="orderby" id="sort">
                  <option value="2">Publicaciones más recientes</option>
                  <option value="3">Publicaciones más antiguas</option>
                  <option value="0">Mayor precio</option>
                  <option value="1">Menor precio</option>
                  <option value="4">Mayor superficie</option>
                  <option value="5">Menor superficie</option>
              </select>
              <i />
              </div>
            </div>
          </div>
          {
            loading &&
            [1,2,3,4].map(element => (
              <ContentLoader
                speed={2}
                width={"100%"}
                height={"24vh"}
                viewBox="0 0 23vh 100%"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="5" y="5" rx="0" ry="0" width="100%" height="23vh" />
            </ContentLoader>
            ))
          }
          <div className="sidebar-properties-body">
            {getLimit(properties).length === 0 && !loading && <h5 className="text-center mt-lg-5 mt-3">No hay resultados para esta búsqueda.</h5> }
            {getLimit(properties).map((property,index) => (
              ((properties).find(element => element.id === selected)?.development?.id > 0 && properties.find(element => element.id === selected)?.development?.display_on_web) || property.id === selected || selected === 0 || selected === 'null' || !selected
                ?  <div className={index !== 0 && "mt-3"}>
                    <Card_Property key={index}
                      map={mapRef} setZoom={setZoom}
                      handlerClick={handlerClick}
                      grid_map={true} property={property} />
                    <hr />
                  </div>
                : <div className="mt-3">
                    <Card_Property
                      map={mapRef} setZoom={setZoom}
                      handlerClick={handlerClick}
                      opacity={true} grid_map={true}
                      property={property} key={index} />
                    <hr />
                  </div>
              )
            )}
            {getLimit(properties).filter(element => element.id === selected).length === 0 && selected !== 0 &&
              <div className={"mt-3"}>
              <Card_Property 
                map={mapRef} setZoom={setZoom}
                handlerClick={handlerClick}
                grid_map={true} property={properties.find(element => element.id === selected)} />
              <hr />
            </div>}
              {maxPropertiesLoaded < properties.length && <div className="w-100 d-flex justify-content-center"><div onClick={() => setMaxPropertiesLoaded(maxPropertiesLoaded + 50)} className="btn d-inline-block btn-red mx-auto">Cargar más propiedades</div></div> }
          </div>
          {/* <Filters menu={menu} setMenu={setMenu} /> */}
        </div>
      </div>
      <div className="modal fade modal-card" id={'exampleModal'} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {selected  ?
              properties.find(element => element.id === selected)?.development?.id > 0 && properties.find(element => element.id === selected)?.development?.display_on_web
                ?   <Card_Development inMap={true} unities={searchUnitiesInResults(properties.find(element => element.id === selected)?.development?.id)} development={properties.find(element => element.id === selected)?.development} />
                :   <Card_Property inMap={true} property={properties.find(element => element.id === selected)} key={selected} />
              :''
            }
          </div>
        </div>
      </div>
      <div className={"map-google "  + ( viewId === 1 && 'full-map')} style={{position:"relative", overflow:"hidden"}}>
        <div className="wrapper-map position-relative">
          <GoogleMapReact
            options={mapOptions}
            bootstrapURLKeys={{
              key: realEstate.keys.google_map,
              libraries: ['drawing'].join(','),
              v:'3.32'
            }}
            libraries={['geometry', 'drawing']}
            zoom={zoom}
            center={center}
            // disableDoubleClickZoom={false}
            disableDoubleClickZoom= {false}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              google.current = maps;
              handleGoogleMapApi(map,maps)
            }}
            onChange={({zoom, bounds}) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng, // Oeste
                bounds.se.lat, // Norte
                bounds.se.lng, // Este
                bounds.nw.lat // Norte
              ]);
              setMapBorder([bounds.nw.lng,bounds.se.lng,bounds.se.lat,bounds.nw.lat])
            }}
          >
            {clusters.map(cluster => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {cluster: isCluster,point_count: pointCount} = cluster.properties;
              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <div
                      className={"cluster-marker " + (selected !== 0 && 'opacity-cluster')}
                      style={{
                        width: `${10 + (pointCount / points.length) * 20}px`,
                        height: `${10 + (pointCount / points.length) * 20}px`
                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }
              return (
				<Marker key={`crime-${cluster.properties.propId}`} lat={latitude} lng={longitude}>
          <button data-bs-toggle="modal" data-bs-target={cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && "#exampleModal"} onClick={() => (viewId === 0 ? setMenu(false) : "") + openModal(cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1) + checkAndSetActive(cluster.properties.propId) + addMarker(cluster.properties.propId)} id={("marker-" + (cluster.property.development?.id > 0 && cluster.property.development.display_on_web ? cluster.property.development?.id : cluster.properties.propId))} className={ (viewId === 1 ? 'd-none' :  'd-none d-lg-block') + " crime-marker "
            + (filters.operation.includes("venta") ? " venta-marker " : 'alquiler-marker ')
            + (selected?.toString() !== '0' && cluster?.properties?.propId.toString() !== selected?.toString()  ? " opacity-marker " : '')
            + (selected?.toString() === cluster?.properties?.propId.toString() ? ' selected-marker ' : '' )
            + (" marker-" + (cluster.property.development?.id > 0 ? cluster.property.development?.id : cluster.properties.propId)) + " "
            + (cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 ? " is_development" :'')}>
            <div className={"center " + (checkMarker((cluster.property.development?.id)?.length > 1 ? cluster.property.development?.id : cluster.properties.propId) ? ' active ' : '' )}>
              <i className={(cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 ? ' icon-marker-emprendimiento ' : " icon-marker ") + (checkMarker((cluster.property.development?.id)?.length > 1 ? cluster.property.development?.id : cluster.properties.propId) ? ' active ' : '' )}></i>
              {filters.operation.includes("venta")  && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('venta')))}</h5>}
              {filters.operation.includes("alquiler") && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web  ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('alquiler')))}</h5>}
              {filters.operation.includes("temporario") && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web  ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('temporario')))}</h5>}
            </div>
          </button>
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => openModal() + checkAndSetActive(cluster.properties.propId) + addMarker(cluster.properties.propId)} id={("marker-" + (cluster.property.development?.id > 0 ? cluster.property.development?.id : cluster.properties.propId))} className={(viewId === 1 ? 'd-block' : 'd-block d-lg-none') + " crime-marker "
            + (filters.operation.includes("venta") ? " venta-marker " : 'alquiler-marker ')
            + (selected?.toString() !== '0' && cluster?.properties?.propId.toString() !== selected?.toString()  ? " opacity-marker " : '')
            + (selected?.toString() === cluster?.properties?.propId.toString() ? ' selected-marker ' : '' )
            + (" marker-" + (cluster.property.development?.id > 0 ? cluster.property.development?.id : cluster.properties.propId)) + " "
            + (cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 ? " is_development" :'')}>
            <div className={"center " + (checkMarker((cluster.property.development?.id)?.length > 1 ? cluster.property.development?.id : cluster.properties.propId) ? ' active ' : '' )}>
              <i className={(cluster.property.development !== null && cluster.property.development.display_on_web && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 ? ' icon-marker-emprendimiento ' : " icon-marker ") + (checkMarker((cluster.property.development?.id)?.length > 1 ? cluster.property.development?.id : cluster.properties.propId) ? ' active ' : '' )}></i>
              {filters.operation.includes("venta")  && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('venta')))}</h5>}
              {filters.operation.includes("alquiler") && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web  ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('alquiler')))}</h5>}
              {filters.operation.includes("temporario") && <h5>{cluster.property.development?.id > 0 && searchUnitiesInResults(cluster.property.development?.id)?.length > 1 && cluster.property.development.display_on_web  ? searchUnitiesInResults(cluster.property.development?.id)?.length : getPriceForMarker(getPrices(cluster.property,('temporario')))}</h5>}
            </div>
          </button>
          {/* {viewId === 1 && cluster.property.developmet !== null &&   */}
            {/* <div className={"card-window " + (cluster.properties.propId !== activeMarker || viewId === 1 ? 'd-none' : 'd-none d-lg-block')}>
              <div className={"card-window-header " + ((sidebar && cluster.property.development === null) && 'd-none')}>
              {cluster.property.development === null ?
                viewId === 1 &&
                <Card_Property inMap={true} property={cluster.property} key={cluster.properties.propId} />
                :
                searchUnitiesInResults(cluster.property.development.id).length > 1 && cluster.property.development.display_on_web ? //Si hay mas de una propiedad en el mismo desarrollo
                  <Card_Development  inMap={true} unities={searchUnitiesInResults(cluster.property.development.id)} development={cluster.property.development} key={cluster.properties.id} />
                :    viewId === 1 && <Card_Property inMap={true} property={cluster.property} key={cluster.properties.propId} />
                }
              </div>
            </div> */}
          {/* } */}
			  </Marker>
              );
            })}
          </GoogleMapReact>
          <div className="control-side">
            <div className="content-button position-relative">
              <button disabled={(polygonCoordinates.length !== 0 && barControllMap !== '') || coordinatesCheck !== undefined} id={"button-polygon"} className={"btn-action map-polygon-icon " + (barControllMap === 'polygon' && "active")} onClick={(e) => setBarControllMap('polygon')}> 
              </button>
                <button id="back-hand" onClick={(e) => setBarControllMap(null)} className={"back-to-hand " + (polygonCoordinates.length === 0 && barControllMap === 'polygon' ? '' : 'd-none')}>
                  <div></div>
                  <div></div>
              </button> 
            </div>
            <div className="content-button position-relative">
              <button disabled={(polygonCoordinates.length !== 0 && barControllMap !== '') || coordinatesCheck !== undefined} id="button-pencil" className={"btn-action " + (barControllMap === 'pencil' && "active")} onClick={(e) => drawAction(e) + setDrawing(!drawing) + setBarControllMap('pencil')}>
                <i className="icon-pencil"></i> 
              </button>
              <button id="back-hand_" onClick={(e) => setBarControllMap(null)} className={"back-to-hand " + (polygonCoordinates.length === 0 && barControllMap === 'pencil' ? '' : 'd-none')}>
                <div></div>
                <div></div>
              </button> 
            </div>
            <button disabled={polygonCoordinates.length !== 0} className={"btn-searcher " + (mapBorder === coordinatesCheck ? ' disabled-map ' : '') + (coordinatesCheck !== undefined ? ' active' : '') } onClick={() => handleSearchProperties(true)}>Ver resultados en esta zona</button>
            <div className="content-button position-relative">
              {/* <div className={"disabled-index " + (polygonCoordinates.length === 0 || coordinatesCheck === undefined ? 'z-index-top' : '')}></div> */}
              <button className={"btn-trash " + ((polygonCoordinates.length !== 0  || coordinatesCheck !== undefined) && 'red-trash')}  style={{lineHeight:"1"}} id="clean" onClick={(e) => e.preventDefault() + cleanerPolygon()}>
                  <i className="icon-trash"></i>
              </button>
            </div>
          </div>
          {(loading ? <Loader width={"full"} /> : '')}
        </div>
        <Paginate viewId={viewId} />
      </div>
    </div>
  ):'';
}
//

export default connect(state => ({
  settings: state.settings,
  properties: state.properties.properties,
  totalCount: state.properties.totalCount,
  selected: state.properties.selected,
  loading: state.properties.loading,
  coordinates: state.properties.coordinates,
  polygonCoordinates: state.properties.polygonCoordinates,
  drawnPolygon: state.properties.drawnPolygon,
  filters: state.properties.filters,
}),null)(getMap);