import React from "react"
import { connect } from "react-redux";
import {getType,getOperationView,textTypes,updateFilters,getView, getFathers, getGrandfathers, getChildrens} from '../../helpers/helper.filters'
import { graphql, useStaticQuery } from 'gatsby';
import SumaryFilters from "./sumary-filters";
import { useEffect, useRef } from "react";
import { useState } from "react";
import $ from 'jquery'
import { getPropertiesAction, redux_updateOrders, REDUX_UPDATE_POLYGON_COORDINATES, REDUX_UPDATE_DRAWN_POLYGON } from "../../redux/propertiesDucks";
import { useLocation } from "@reach/router";

const Handler = ({setViewId, viewId, setMenu, menu, filters, loading, allLocations, totalCount, dispatch}) => {
    
    const history = useLocation();
    const [orders,setOrders] = useState({order:'desc',order_by:'id'})
    const [auxFilters,setAuxFilters] = useState()
    const selectorOrder = useRef()

    function traductorSelect(){
        switch(selectorOrder.current.value){
          case '0': setOrders({order:'desc',order_by:'price'}); break;
          case '1': setOrders({order:'asc',order_by:'price'}); break;
          case '2': setOrders({order:'desc',order_by:'id'}); break;
          case '3': setOrders({order:'asc',order_by:'id'}); break;
          case '4': setOrders({order:'desc',order_by:'total_surface'}); break;
          case '5': setOrders({order:'asc',order_by:'total_surface'}); break;
        }
    }

    useEffect(() => {
        dispatch(redux_updateOrders(orders))
    },[orders])

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
        }
    }`)

    const [sticky,setSticky] = useState(false)

    useEffect(() => {
        const main = document.querySelector('#properties');
        const handler_bar = document.querySelector('#handler-bar');
     
        window.onscroll = function () {
        if (window.pageYOffset > (main.offsetHeight - handler_bar.offsetHeight)) {
            setSticky(true)
        } else {
            setSticky(false)
        }
       }
    })

    useEffect(() => {
        $("html, body").animate({ scrollTop: 0 }, "slow")
    },[viewId])

    useEffect(() => {
        // setAuxFilters(filters)
        // setAuxFilters({...filters,
        //     operation:getView(history)
        // })
    },[filters])
    
    const checkAllFatherActives = (item) => { // Se fija si todos los hijos de un abuelo estan activos
        for(const father of getFathers(item)){
            for(let i = 0; i < filters.location.length; i++) {
                if(filters.location[i].name === father[0]){
                }
            }
            if(filters.location.find(element => element.name.toString() === father[0].toString())){
            }
            else{
                return false
            }
        }
        return true
    }

    const checkCitiesActive = (parent) => { // // Se fija si todos los hijos de un padre estan activos
        if(Object.keys(parent[1]).length === 2){
            if(filters.location.find(element => element.name.toString() === parent[0].toString())){
                return true
            }
            return false;
        }
        for(const children of getChildrens(parent)){
            if(filters.location.find(element => element.name.toString() === children[0].toString())){
            }
            else{
                return false
            }
        }
        return true
    }

    //Esta es un test. REVISAN EN CUANTO ALGO SE ROMPA
    const checkNameActive = (parent) => {
        if(filters.location.find(element => element.name.toString() === parent[0].toString())){
            return true
        }
        return false
    }

    const groupLocations = () => {
        if(!allLocations.meta){return []}
        const locations_grouped = []
        for(let grandfather of getGrandfathers(allLocations)){
            if(checkAllFatherActives(grandfather)){
                locations_grouped.push({...grandfather, "is":"grandfather"})
            }
            else{
                for(let father of getFathers(grandfather)){
                    if(checkCitiesActive(father) || checkNameActive(father)){
                        // locations_grouped.push(father)
                        locations_grouped.push({...father, "is":"father"})
                    }
                    else{
                        for(let children of getChildrens(father)){
                            if(filters.location.find(element => element.name.toString() === children[0].toString())){
                                // locations_grouped.push(children)
                                // locations_grouped.push(children)
                                locations_grouped.push({...children, "is":"children"})
                            }
                        }
                    }
                }
            }
        }
        return locations_grouped
    }

    const updateLocationByGrandFather = (item) => {
        const parentFormated = getFathers(item).map( // Formateo todos los hijos de este abuelo.
            element => ({"id":element[1].id,"name":element[0]})
        )
        let newLocations = []
        for(let i = 0; i < filters.location.length; i++){ //Recorro los filtros activos para eliminar todos los hijos de este abuelo activo.
            if(parentFormated.find(element => element.id === filters.location[i].id)){ //Si encuentro un hijo activo, no lo agrego.
            }
            else{
                newLocations.push({id:filters.location[i].id,name:filters.location[i].name})//Mantengo los filtros activos que no pertenecer a este abuelo.
            }
        }
        if(!checkAllFatherActives(item)){
            newLocations = [...newLocations, ...parentFormated] //Si no estan todos los hijos de este abuelo activos, los activo.
        }
        else{
            for(let i = 0; i < getFathers(item).length; i++){
                const father = getFathers(item)[i];
                for(let c = 0; c < getChildrens(father).length; c++){
                    const children = getChildrens(father)[c];
                    newLocations = newLocations.filter(element => element.id !== children[1].id) // Elimino los nietos que estan activos.
                }
                //Axel acá tenes eliminar todos los "Nietos" activos que tiene este abuelo. REVISAR PORQUE NO FUNCIONA. ATENCION
            }
        }
        return updateFilters(setAuxFilters,filters,"allLocations", newLocations)
        // return null
        // return setRouter({...router,location:newLocations}) // Si todos los los hijos de este abuelo están activos, los desactivo.
    }

    const updateLocationByParent = (item) => {
        let newLocations = filters.location;
        for(let i = 0; i < getGrandfathers(allLocations).length; i++){ // Me fijo si el abuelo tiene todos los hijos activos, los desactivo y activo solo este padre que estoy clickeando.
            if(getFathers(getGrandfathers(allLocations)[i]).find(element => element[0] === item[0])){
                if(checkAllFatherActives(getGrandfathers(allLocations)[i])){ //Si llegan a estar todos los hijos activos, los desactivo y dejo solo este activo.
                    for(let j = 0; j < getFathers(getGrandfathers(allLocations)[i]).length; j++){ // Recorro todos los "Hermanos" de este padre
                        const brother = getFathers(getGrandfathers(allLocations)[i])[j]
                        for(let c = 0; c < getChildrens(brother).length; c++){ // Recorro los hijos de los hermanos y tambien los deshabilito.
                            let children = getChildrens(brother)[c]
                            newLocations = newLocations.filter(location => location.id !== children[1].id)
                        }
                        newLocations = newLocations.filter(location => location.id !== brother[1].id) //Saco todos los hermanos
                    }
                    if(getChildrens(item).length > 0){ 
                        for(let c = 0; c < getChildrens(item).length; c++){
                            newLocations = [...newLocations, {id:getChildrens(item)[c][1].id,name:getChildrens(item)[c][0]}]
                        }
                        return updateFilters(setAuxFilters,filters,"allLocations", newLocations) //Si este padre tiene hijos, los activo a ellos.
                    }
                    return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item[1].id,name:item[0]}]) //Si este padre tiene hijos, los activo a ellos.
                }
            }
        }
        if(Object.keys(item[1]).length === 2){ // Si no tiene hijos el padre (CHEQUEAR ESTO)
            // return updateLocation({id:item[1].id,name:item[0]})
            return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item[1].id))
        }
        if (checkCitiesActive(item)){ // Si todos los hijos del padre están activos, los desactivo.
            for(const children of getChildrens(item)){
                newLocations = newLocations.filter(element => element.id !== children[1].id)
            }
            return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item[1].id)) //Si este padre tiene hijos, los activo a ellos.
        }
        for(const children of getChildrens(item)){
            newLocations = newLocations.filter(element => element.id !== children[1].id)
            newLocations.push({id:children[1].id,name:children[0]})
        }
        return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item[1].id,name:item[0]}]) //Si este padre tiene hijos, los activo a ellos.
    }

    const updateLocationByChildren = (item) => {
        let newLocations = filters.location;
        return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item[1].id))
    }

    const updateLocationByGroup = (location) => {
        switch(location.is){
            case 'grandfather' : 
                return updateLocationByGrandFather(location) //
            case 'father' : 
                return updateLocationByParent(location) //
            case 'children' : 
                return updateLocationByChildren(location) //
            }
    }

    return  (
        <>
            <div id="handler-bar" className={(viewId !== 3 && " full-sticky")}>
                <div className="container-fluid py-lg-1 not-closer">
                    <div className="d-flex justify-content-between align-items-center not-closer w-100">
                        <div className="info-section not-closer w-100">
                            <div>
                                <SumaryFilters viewId={viewId} auxFilters={auxFilters} setAuxFilters={setAuxFilters} setMenu={setMenu} filters={filters} totalCount={totalCount} />
                            </div>
                            <div className={ (viewId !== 3 && 'd-none' )}>
                                <div class="section-title">
                                    <h1>{getType(filters)?.length > 0 ? textTypes(getType(filters)) : 'Propiedades'} en {getOperationView(filters)} - {realEstate.name}</h1>
                                    {/* <br /> */}
                                    <h5 className="mb-0">{loading ? 'Cargando' : totalCount} resultados totales de búsqueda. </h5>
                                </div>
                            </div>
                        </div>
                        <div className="contaner-fluid">
                            <div className="handlers-items d-flex align-items-center">
                                <div onClick={() => setViewId(0)} className={"btn order-lg-2 order-3 button-item d-none d-lg-flex " + (viewId===0 && 'active')}><i className="icon-grid_location" /></div>
                                <div onClick={() => setViewId(1)} className={"btn order-lg-3 order-2 button-item " + (viewId===1 && 'active')}> <i className="icon-location" /></div>
                                <div onClick={() => setViewId(2)} className={"btn order-lg-1 order-1 button-item " + (viewId===2 && 'active')}> <i className="icon-grid-view" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                    </div>
                </div>
            </div>
            {
                <div className="not-closer d-none d-lg-flex current-filters-sumary">
                    <div className="container-fluid">
                        <span>Filtros aplicados: </span>
                        <div className="scroller">
                            {(<div className="d-inline-block">{<div className={"tag tag-button d-inline-flex align-items-center justify-content-center " + filters?.operation?.toLowerCase()}>{filters.operation.replace("-"," ")}</div>}</div>)}
                            {groupLocations().map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item[0]} <div onClick={() => updateLocationByGroup(item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                            {/* {filters.location.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'location',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))} */}
                            {filters.type.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'type',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                            {filters.price.type !== '' ? (<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.price.type} <div onClick={() =>  updateFilters(setAuxFilters,filters,'price-type',"null")} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>):''}
                            {(filters.price.type !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">Desde: $ {filters.price.priceMin} Hasta: $ {filters.price.priceMax} <div onClick={() =>  updateFilters(setAuxFilters,filters,'price-type',"null")} className="btn-delete-tag cursor-pointer ms-1">×</div></div> : '')}
                            {filters.surface.type !== '' ?  (<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.surface.type} <div onClick={() =>  updateFilters(setAuxFilters,filters,'surface-type','')} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>):''}
                            {(filters.surface.type !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">Desde: {filters.surface.surfaceMin} M2 Hasta: {filters.surface.surfaceMax} M2<div onClick={() =>  updateFilters(setAuxFilters,filters,'surface-type','')} className="btn-delete-tag cursor-pointer ms-1">×</div></div> : '')}
                            {(filters.environments !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center not-capitalize">
                                {
                                    filters?.environments?.split("-")[0] === filters?.environments?.split("-")[1]
                                    ?   (filters?.environments?.split("-")[0] !== '0' && (filters?.environments?.split("-")[0].toString() === '99' ? '+9' : filters?.environments?.split("-")[0]) + " amb.")
                                    :   (filters?.environments?.split("-")[0] !== '0' ? "Desde " + filters?.environments?.split("-")[0] : '') + (filters.environments.split("-")[1] !== '99' || filters.environments.split("-")[0] === '0' ? " hasta " + filters.environments.split("-")[1]:'') + " amb."
                                }
                                    <div onClick={() => updateFilters(setAuxFilters,filters,'environments',"")} className="btn-delete-tag cursor-pointer ms-1">×</div>
                            </div> : '')}
                            {(filters?.bedrooms !== '' ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center not-capitalize">
                                {
                                    filters?.bedrooms?.split("-")[0] === filters?.bedrooms?.split("-")[1]
                                    ?   (filters?.bedrooms?.split("-")[0] !== '0' && (filters?.bedrooms?.split("-")[0].toString() === '99' ? '+9' : filters?.bedrooms?.split("-")[0]) + " dorm")
                                    :   (filters?.bedrooms?.split("-")[0] !== '0' ? "Desde " + filters?.bedrooms?.split("-")[0] : '') + (filters.bedrooms.split("-")[1] !== '99' || filters.bedrooms.split("-")[0] === '0' ? " hasta " + filters.bedrooms.split("-")[1]:'') + " dorm"
                                }
                                    <div onClick={() => updateFilters(setAuxFilters,filters,'bedrooms',"")} className="btn-delete-tag cursor-pointer ms-1">×</div>
                            </div> : '')}
                            {/* {filters.environments.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.replaceAll(" Ambientes"," Amb").replaceAll(" Ambiente"," Amb")} <div onClick={() =>  updateFilters(setAuxFilters,filters,'environments',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))} */}
                            {(filters.age !== '' && filters.age !== undefined ? <div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters?.age} {filters.age?.includes("años") ? ' de antigüedad' : ''}<div onClick={() =>  updateFilters(setAuxFilters,filters,'age','')} className="btn-delete-tag cursor-pointer ms-1">×</div></div> : '')}
                            {/* {filters.environments !== ''? (<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{filters.environments} <div onClick={() =>  updateFilters(setAuxFilters,filters,'environments',filters.environments)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>):''} */}
                            {filters.environments_types.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'environments_types',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                            {filters.services.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'services',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                            {filters.specials.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'specials',makeCustom(item))} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                            {filters.generals.map((item,index) =>(<div className="d-inline-block">{<div className="tag tag-button d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setAuxFilters,filters,'generals',item)} className="btn-delete-tag cursor-pointer ms-1">×</div></div>}</div>))}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default connect(state => ({
    filters: state.properties.filters,
    totalCount: state.properties.totalCount,
    allLocations:state.properties.allLocationFilters,
    loading: state.properties.loading
}),null)(Handler);