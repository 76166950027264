import React from 'react'

const Loader = (props) => {
    return (
        <>  
            <div className={"section-loader-map " + (props.width === 'full' && 'w-100')}>
                <span class="loader"></span>
            </div>
        </>
    )
}

export default Loader
