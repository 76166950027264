import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from "gatsby"

const data = {
  title: ["Lo siento, página", "no encontrada"],
  subtitle: "No se pudo encontrar la página que solicitaste.",
  cta: {
    text: "Regresar al inicio",
    link: "/"
  }
}

const isBrowser = typeof window !== "undefined"

const NotFoundPage = () => (
  isBrowser && <Layout>
    <Seo title="404: Not found" />
    <div className="nf-container d-flex flex-column justify-content-center h-100">
      <div className="nf-top d-flex justify-content-center align-items-lg-end align-items-center text-center">
        <h1 className="nf-number close-text">404</h1>
      </div>
      <div className="nf-text d-flex justify-content-center nf-bg-color nf-bt-border">
        <div className="nf-text-wrapper d-flex flex-column justify-content-center align-items center text-center">
          <h2 className="nf-title mt-3">
            {data.title[0]}
            <br />
            {data.title[1]}
          </h2>
          <p className="nf-subtitle">{data.subtitle}</p>
          <div
            onClick={() => navigate(data.cta.link)}
            className="btn white border nf-btn mx-auto mt-5 mb-3"
          >
            {data.cta.text}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
