import {useEffect,useState} from 'react'
import React from 'react';
import ContentLoader from 'react-content-loader'
import { getPropertiesAction,changeOffsetPropertiesAction } from '../redux/propertiesDucks';
import { connect } from 'react-redux'
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import $ from 'jquery'

const PaginatedItems = ({totalCount,totalListFilters,viewId,loading,offset,dispatch,filters,totalCountVenta,totalCountAlquiler}) => {
    
  const itemsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(offset / itemsPerPage);
  const [items, setItems] = useState([0,1,2]);
  const [fixed,setFixed] = useState(false)


  const location = useLocation()

  const getItems = (data) => {
    let items=[]
    for (let index = 0; index < Math.ceil(data/itemsPerPage); index++) {
      items.push(index)
    }
    return items ;
  } 

  useEffect(() => {
    setCurrentPage(offset / itemsPerPage)
    // console.log(viewId)
  },[viewId])


  useEffect(() => {
    
    setItems(getItems(totalCount))
    if(loading){
      setFixed(false)
    }
    if(offset === 0){
      // setFixed(true)
      setCurrentPage(0)
    }
  },[offset,totalCount])

  useEffect(() => {
    dispatch(changeOffsetPropertiesAction(currentPage*itemsPerPage))
    if(location.pathname.includes(filters.operation?.toLowerCase())){
      dispatch(getPropertiesAction())
    }
  },[currentPage])


    return items.length > 1 && (
      <>
            <div className="mt-4 pt-0 pagination d-flex align-items-center flex-column justify-content-center">
                <>
                  <div id="paginator" className="d-flex align-items-center justify-content-center">
                    {/* <div onClick={() => setCurrentPage( ? items[items.length-1] : currentPage-1)} className={"cursor-pointer pagination-item d-flex justify-content-center align-items-center " + (currentPage === 0 ? 'opacity-disabled' : '')}> */}
                    <div onClick={() =>  $("html, body").animate({ scrollTop: 0 }, "slow") + (items[0] !== currentPage ? setCurrentPage(currentPage-1) : '')} className={"cursor-pointer pagination-item d-flex justify-content-center align-items-center " + (currentPage === 0 ? 'opacity-disabled' : '')}>
                        {""}
                    </div>
                    <div className="container-pagination d-flex align-items-center">
                        {/* <div className={"item-paginate points  " + (currentPage > (items[0]+1) ? '' : ' opacity ')}>
                            ...
                        </div> */}
                        <div className={"item-paginate opacity " + (currentPage === items[0] ? '' : ' d-none ')}>
                            0-000
                        </div>
                        {items.map((item, index) => (
                          <div onClick={() =>  $("html, body").animate({ scrollTop: 0 }, "slow") + setCurrentPage(item)} className={"item-paginate " + (item === currentPage && ' active') + (currentPage === item || currentPage === item+1 || currentPage === item-1 ? '' : ' d-none ')}>
                              {item > 0 ? item*itemsPerPage + 1 : item*itemsPerPage} a {((item+1)*itemsPerPage > totalCount ? totalCount : (item+1)*itemsPerPage)}
                          </div>
                        ))}
                        <div className={"item-paginate opacity " + (currentPage === items[items.length -1] ? '' : ' d-none ') }>
                            0-000
                        </div>
                        {/* <div className={"item-paginate points end  " + (currentPage < (items[items.length-1]-1) ? '' : ' opacity ')}>
                            ...
                        </div> */}
                    </div>
                    <div onClick={() => $("html, body").animate({ scrollTop: 0 }, "slow") + (items[items.length-1] !== currentPage ? setCurrentPage(currentPage+1) : '')} className={"cursor-pointer next pagination-item d-flex justify-content-center align-items-center " + (currentPage === items[items.length-1] ? 'opacity-disabled' : '')}>
                        {""}
                    </div>
                  </div>
                  <h5 className='d-block mt-lg-2 mt-1'>{loading ? 'Cargando resultados..' : <><strong> {totalCount} </strong> de <strong>{filters.operation === 'venta' ? totalCountVenta : totalCountAlquiler}</strong>  propiedades en {filters.operation}</>}</h5>
                </>
            </div>
      </>
    );
  }

  export default connect(state => ({
    totalCount: state.properties.totalCount,
    totalListFilters: state.properties.totalListFilters,
    totalCountAlquiler: state.properties.totalCountAlquiler,
    totalCountVenta: state.properties.totalCountVenta,
    offset: state.properties.offset,
    filters: state.properties.filters,
    loading: state.properties.loading
}),null)(PaginatedItems);
