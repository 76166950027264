import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    checkCurrentFilters,
    updateFilters,
    getView,
    checkEnvironment,
    getLocationCount,
    getTypeCount,
    getTagCount,
    getGenerals,
    getEnvironment,
    getServices,
    checkBedroom,
    getGrandfathers,
    getFathers,
    getChildrens,
    normalizeName,
} from '../../helpers/helper.filters'

import {REDUX_UPDATE_COORDINATES, REDUX_UPDATE_FILTERS,REDUX_UPDATE_DRAWN_POLYGON, getAllLocationsAction} from '../../redux/propertiesDucks'
import { useLocation } from '@reach/router';
import $ from 'jquery'
import TrashSvg from "../../images/svg/trash.svg"
import { Markup } from 'interweave';
import { deleteSharp } from '../../helpers/helper.map';

const Sumary_Filter = ({viewId, auxFilters,setAuxFilters,tokko_filters,total_tokko_filters,listLocationFilters,allLocations,loading_Alllocatons,loading_locations,totalCountAlquilerTemp,listDynamicFilters,totalCountAlquilerTemporario,totalCountAlquiler,totalCountVenta,filters,orders,polygonCoordinates,drawnPolygon,api_key,totalCount,custom_tags,dispatch,menu,setMenu}) => {
    
    const history = useLocation();
    const [locations, setLocations] = useState([]);

    const [location,setLocation] = useState(history.pathname.split("/"))
    const [searcher,setSearcher] = useState("")

    const [openType,setOpenType] = useState(false)
    const [openOperation,setOpenOperation] = useState(false)
    const [openLocation,setOpenLocation] = useState(false)
    const [openEnvironment,setOpenEnvironment] = useState(false)
    const [openMinEnvironment,setOpenMinEnvironment] = useState(false)
    const [openMaxEnvironment,setOpenMaxEnvironment] = useState(false)
    const [openMinBedrooms,setOpenMinBedrooms] = useState(false)
    const [openMaxBedrooms,setOpenMaxBedrooms] = useState(false)

    const [openPrice,setOpenPrice] = useState(false)
    const [openSurface,setOpenSurface] = useState(false)
    const [openGenerals,setOpenGenerals] = useState(false)
    const [openTypeEnvoirments,setOpenTypeEnvoirments] = useState(false)
    const [openServices,setOpenServices] = useState(false)
    const [openAge,setOpenAge] = useState(false)    

    const [environments,setEnvironments] = useState({min:'',max:''})
    const [bedrooms,setBedrooms] = useState({min:'',max:''})

    const [auxiliarValuesPrice,setAuxiliarPrice] = useState({
        priceType:'',
        priceMin:'',
        priceMax:'',
    })

    const [auxiliarValuesSurface,setAuxiliarSurface] = useState({
        surfaceType:'',
        surfaceMin:'',
        surfaceMax:'',
    })

    const pricesDefault = [20000,40000,60000,80000,100000,200000,400000,600000,800000,1000000]
    const [helpers,setHelpers] = useState({minprice:0,maxprice:0,minarea:0,maxarea:0}) // minprice,maxprice,minarea,maxarea

    useEffect(() => {
        if(filters.operation && filters !== auxFilters && checkContent(filters)){
            setAuxFilters(filters)
            if(checkCurrentFilters(filters,"surface-type","total")){
                setAuxiliarSurface({...auxiliarValuesSurface, 
                    surfaceType : "total",
                    surfaceMin : filters.surface.surfaceMin,
                    surfaceMax : filters.surface.surfaceMax
                })
            }
            if(checkCurrentFilters(filters,"surface-type","cubierta")){
                setAuxiliarSurface({...auxiliarValuesSurface, 
                    surfaceType : "cubierta",
                    surfaceMin : filters.surface.surfaceMin,
                    surfaceMax : filters.surface.surfaceMax
                })
            }
            if(checkCurrentFilters(filters,"price-type","pesos")){
                setAuxiliarPrice({...auxiliarValuesPrice, 
                    priceType : "pesos",
                    priceMin : filters.price.priceMin,
                    priceMax : filters.price.priceMax
                })
            }
            if(checkCurrentFilters(filters,"price-type","dolares")){
                setAuxiliarPrice({...auxiliarValuesPrice, 
                    priceType : "dolares",
                    priceMin : filters.price.priceMin,
                    priceMax : filters.price.priceMax
                })
            }
        }
        if(filters.surface?.type === ''){
            setAuxiliarSurface({
                surfaceType:'',
                surfaceMin:'',
                surfaceMax:'',
            })
        }
        if(filters.price?.type === ''){
            setAuxiliarPrice({
                priceType:'',
                priceMin:'',
                priceMax:'',
            })
        }
        if(filters.environments === ''){
            setEnvironments({min:'',max:''})
        }
        if(filters.bedrooms === ''){
            setBedrooms({min:'',max:''})
        }
    },[filters])

    useEffect(() => {
      if (total_tokko_filters.data) {
        for (let index = 0; index < total_tokko_filters.data.objects.locations.length; index++) {
          if(locations.length <= 0){
            setLocations((locations) => [
              ...locations,
              total_tokko_filters.data.objects.locations[index].location_name,
            ]);
          }
        }
      }
    }, [total_tokko_filters]);

    useEffect(() => {
        if(api_key && auxFilters){
            dispatch(REDUX_UPDATE_FILTERS(auxFilters))
        }
    }, [auxFilters,api_key]);

    useEffect(() => {
        if(api_key && !allLocations?.locations){
            dispatch(getAllLocationsAction())
        }
    },[api_key,allLocations])

    useEffect(() => {
        let interval = ''
        if(filters.environments !== (`${environments.min}-${environments.max}`)){
            if(environments.min || environments.max) {
                if(environments.min !== '' || environments.max !== ''){
                    interval = (`${environments.min}-${environments.max}`)
                }
                if (environments.min !== '' && environments.max === ''){
                    interval = (`${environments.min}-99`)
                }
                if(environments.max !== '' && environments.min === ''){
                    interval = (`0-` + environments.max)
                }
                return updateFilters(setAuxFilters,auxFilters,"environments",interval)
            }
        }
    },[environments])

    useEffect(() => {
        let interval = ''
        if(filters.bedrooms !== (`${bedrooms.min}-${bedrooms.max}`)){
            if(bedrooms.min || bedrooms.max) {
                if(bedrooms.min !== '' && bedrooms.max !== ''){
                    interval = (`${bedrooms.min}-${bedrooms.max}`)
                }
                if (bedrooms.min !== '' && bedrooms.max === ''){
                    interval = (`${bedrooms.min}-99`)
                }
                if(bedrooms.max !== '' && bedrooms.min === ''){
                    interval = (`0-` + bedrooms.max)
                }
                return updateFilters(setAuxFilters,auxFilters,"bedrooms",interval)
            }
        }
    },[bedrooms])

    const checkContent = (filters) => {
        const prot_filter = {
            location: [],
            type: [],
            operation: "",
            environments: '',
            bedrooms: "",
            age:'',
            price:{
              type:'',
              priceMin:'',
              priceMax:''
            },
            surface:{
              type:'',
              surfaceMin:"",
              surfaceMax:"",
            },
            environments_types: [],
            generals: [],
            services: [],
            specials: [],
        }
        if(filters === prot_filter){
            return false
        }
        return true
    }

    const checkEnvironmentExist = () => {
        let counter = 0
        for (let index = 0; index < 10; index++) {
            if(checkEnvironment(listDynamicFilters.data.objects,index)?.amount > 0 && checkEnvironment(listDynamicFilters.data.objects,index)?.count > 0){
                counter++
            }
        }
        if(counter > 0){
            return true
        }
        return false
    }

    const checkBedroomExist = () => {
        let counter = 0
        for (let index = 0; index < 10; index++) {
            if(checkBedroom(listDynamicFilters.data.objects,index)?.amount > 0 && checkBedroom(listDynamicFilters.data.objects,index)?.count > 0){
                counter++
            }
        }
        if(counter > 0){
            return true
        }
        return false
    }

    const sendAuxiliar = () => {
        updateFilters(setAuxFilters,auxFilters,"price-type", auxiliarValuesPrice.priceType)
        updateFilters(setAuxFilters,auxFilters,"price-min", auxiliarValuesPrice.priceMin)
        updateFilters(setAuxFilters,auxFilters,"price-max", auxiliarValuesPrice.priceMax)
        updateFilters(setAuxFilters,auxFilters,"surface-type", auxiliarValuesSurface.surfaceType)
        updateFilters(setAuxFilters,auxFilters,"surface-min", auxiliarValuesSurface.surfaceMin)
        updateFilters(setAuxFilters,auxFilters,"surface-max", auxiliarValuesSurface.surfaceMax)
    }
    
    const cleanerPolygon = () => {
        deleteSharp(drawnPolygon,dispatch,REDUX_UPDATE_DRAWN_POLYGON)
        // drawnPolygon.setMap(null)
        // dispatch(REDUX_UPDATE_DRAWN_POLYGON(null))
    }

    const updateEnvironment = (type,value) => {
        if(type === 'min'){
            return setEnvironments({...environments,min:value})
        }
        if(type === 'max'){
            return setEnvironments({...environments,max:value})
        }
    }

    const updateBedroom = (type,value) => {
        if(type === 'min'){
            return setBedrooms({...bedrooms,min:value})
        }
        if(type === 'max'){
            return setBedrooms({...bedrooms,max:value})
        }
    }

    $(document).on('mouseover',function(e){
        if(e?.target?.className){
            if(e?.target?.className?.includes("not-closer")){
            }
            else{
                setOpenType(false)
                setOpenOperation(false)
                setOpenLocation(false)
                setOpenEnvironment(false)
                setOpenPrice(false)
                setOpenSurface(false)
                setOpenGenerals(false)
                setOpenTypeEnvoirments(false)
                setOpenServices(false)
                setOpenAge(false)
                setOpenMinEnvironment(false)
                setOpenMaxEnvironment(false)
                setOpenMinBedrooms(false)
                setOpenMaxBedrooms(false)
            }
        }
    })

    const checkCitiesActive = (parent) => { // // Se fija si todos los hijos de un padre estan activos
        if(Object.keys(parent[1]).length === 2){
            if(filters.location.find(location => location.id.toString() === parent[1].id.toString())){
                return true
            }
            return false;
        }
        for(const children of getChildrens(parent)){
            if(filters.location.find(element => element.id === children[1].id)){
            }
            else{
                return false
            }
        }
        return true
    }

    const updateLocationByParent = (item) => {
        let newLocations = filters.location;
        for(let i = 0; i < getGrandfathers(allLocations).length; i++){ // Me fijo si el abuelo tiene todos los hijos activos, los desactivo y activo solo este padre que estoy clickeando.
            if(getFathers(getGrandfathers(allLocations)[i]).find(element => element[0] === item[0])){
                if(checkAllFatherActives(getGrandfathers(allLocations)[i])){ //Si llegan a estar todos los hijos activos, los desactivo y dejo solo este activo.
                    for(let j = 0; j < getFathers(getGrandfathers(allLocations)[i]).length; j++){ // Recorro todos los "Hermanos" de este padre
                        const brother = getFathers(getGrandfathers(allLocations)[i])[j]
                        for(let c = 0; c < getChildrens(brother).length; c++){ // Recorro los hijos de los hermanos y tambien los deshabilito.
                            let children = getChildrens(brother)[c]
                            newLocations = newLocations.filter(location => location.id !== children[1].id)
                        }
                        newLocations = newLocations.filter(location => location.id !== brother[1].id) //Saco todos los hermanos
                    }
                    if(getChildrens(item).length > 0){ 
                        for(let c = 0; c < getChildrens(item).length; c++){
                            newLocations = [...newLocations, {id:getChildrens(item)[c][1].id,name:getChildrens(item)[c][0]}]
                        }
                        return updateFilters(setAuxFilters,filters,"allLocations", newLocations) //Si este padre tiene hijos, los activo a ellos.
                    }
                    return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item[1].id,name:item[0]}]) //Si este padre tiene hijos, los activo a ellos.
                }
            }
        }
        if(Object.keys(item[1]).length === 2){ // Si no tiene hijos el padre (CHEQUEAR ESTO)
            if(newLocations.find(element => element.id === item[1].id))
                return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item[1].id))
            return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item[1].id,name:item[0]}])
            
        }
        if (checkCitiesActive(item)){ // Si todos los hijos del padre están activos, los desactivo.
            for(const children of getChildrens(item)){
                newLocations = newLocations.filter(element => element.id !== children[1].id)
            }
            return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item[1].id)) //Si este padre tiene hijos, los activo a ellos.
        }
        for(const children of getChildrens(item)){
            newLocations = newLocations.filter(element => element.id !== children[1].id)
            newLocations.push({id:children[1].id,name:children[0]})
        }
        return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item[1].id,name:item[0]}]) //Si este padre tiene hijos, los activo a ellos.
    }

    const updateLocationByChildren = (item) => {
        let newLocations = filters.location;
        if(filters.location.find(element => element.id === item.id)){
            return updateFilters(setAuxFilters,filters,"allLocations", newLocations.filter(element => element.id !== item.id))
        }
        return updateFilters(setAuxFilters,filters,"allLocations", [...newLocations, {id:item.id,name:item.name}])
    }

    const updateLocationByGrandFather = (item) => {
        const parentFormated = getFathers(item).map( // Formateo todos los hijos de este abuelo.
            element => ({"id":element[1].id,"name":element[0]})
        )
        let newLocations = []
        for(let i = 0; i < filters.location.length; i++){ //Recorro los filtros activos para eliminar todos los hijos de este abuelo activo.
            if(parentFormated.find(element => element.id === filters.location[i].id)){ //Si encuentro un hijo activo, no lo agrego.
            }
            else{
                newLocations.push({id:filters.location[i].id,name:filters.location[i].name})//Mantengo los filtros activos que no pertenecer a este abuelo.
            }
        }
        if(!checkAllFatherActives(item)){
            newLocations = [...newLocations, ...parentFormated] //Si no estan todos los hijos de este abuelo activos, los activo.
        }
        else{
            for(let i = 0; i < getFathers(item).length; i++){
                const father = getFathers(item)[i];
                for(let c = 0; c < getChildrens(father).length; c++){
                    const children = getChildrens(father)[c];
                    newLocations = newLocations.filter(element => element.id !== children[1].id) // Elimino los nietos que estan activos.
                }
                //Axel acá tenes eliminar todos los "Nietos" activos que tiene este abuelo. REVISAR PORQUE NO FUNCIONA. ATENCION
            }
        }
        return updateFilters(setAuxFilters,filters,"allLocations", newLocations)
    }

    const checkAllFatherActives = (item) => { // Se fija si todos los hijos de un abuelo estan activos
        for(const father of getFathers(item)){
            if(filters.location.find(element => element.id === father[1].id)){
            }
            else{
                return false
            }
        }
        return true
    }

    function toMarkup(text,searcherOperation) {
        if(searcherOperation === ''){
            return text.toLowerCase()
        }
        text = text.toLowerCase().replaceAll(searcherOperation.toLowerCase(), "<b>" + searcherOperation.toLowerCase() +"</b>");
        return text;
    }

    const checkResults = (type) => {
        if (type === 'location'){
            for (let grandfather of getGrandfathers(allLocations)){
                if(grandfather[0].toLowerCase().includes(searcher.toLowerCase())){
                    return false
                }
                for(let father of getFathers(grandfather)){
                    if(father[0].toLowerCase().includes(searcher.toLowerCase())){
                        return false
                    }
                    for(let children of getChildrens(father)){
                        if(children[0].toLowerCase().includes(searcher.toLowerCase())){
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        else{
            for(let type of listFilters.data.objects.property_types ){
                if(type.type.toLowerCase().includes(searcherType.toLowerCase())){
                    return false
                }
            }
            return true
        }
    }

    return tokko_filters.data && total_tokko_filters.data && listDynamicFilters.data && listLocationFilters.data ? (
        <>
            <aside id="sumary-filters" className={"sumary-filter position absolute col-12 d-block not-closer"}>
                <form className="d-flex not-closer">
                    <div className={"d-none d-lg-flex content-field not-closer " + (!openOperation && 'close')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={() => setOpenAge(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenPrice(false) + setOpenEnvironment(false) + setOpenType(false) + setOpenLocation(false) + setOpenOperation(!openOperation)}>
                            Tipo de operación
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select">
                                {/* <option value="all">Tipo de propiedad</option> */}
                                {/* <span>Tipo de operación</span> */}
                                {total_tokko_filters.data && total_tokko_filters.data.objects.operation_types.find(element => element.operation_type === 1) ? 
                                        <div className={'not-closer flex-input'}>
                                            <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"operation", "venta")} checked={checkCurrentFilters(filters,"operation",'venta')} type="checkbox" name="operation" id={"venta"} value={1}/>
                                            <label className="not-closer" htmlFor={"venta"}>
                                                <div className="radio custom-span not-closer"></div>
                                                {"Venta "} ({totalCountVenta})
                                            </label>
                                    </div>
                                    : '' }
                                {total_tokko_filters.data && total_tokko_filters.data.objects.operation_types.find(element => element.operation_type === 2) ? 
                                    <div className={'not-closer flex-input'}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"operation", "alquiler")} checked={checkCurrentFilters(filters,"operation",'alquiler')} type="checkbox" name="operation" id={"alquiler"} value={2}/>
                                        <label className="not-closer" htmlFor={"alquiler"}>
                                            <div className="radio custom-span not-closer"></div>
                                            {"Alquiler "} ({totalCountAlquiler})
                                        </label>
                                    </div>
                                        // <div onClick={() => updateFilters(setAuxFilters,auxFilters,"operation", "alquiler")} className='not-closer flex-input cursor-pointer'>
                                        //     <div className={'radio-custom ' + (filters.operation === 'alquiler' && 'active')}>Alquiler</div>
                                        // </div>
                                    : '' }
                                {total_tokko_filters.data && total_tokko_filters.data.objects.operation_types.find(element => element.operation_type === 3) ? 
                                        <div className={'not-closer flex-input'}>
                                            <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"operation", "alquiler-temporario")} checked={checkCurrentFilters(filters,"operation",'alquiler-temporario')} type="checkbox" name="operation" id={"alquiler-temporario"} value={2}/>
                                        <label className="not-closer" htmlFor={"alquiler-temporario"}>
                                            <div className="radio custom-span not-closer"></div>
                                            {"Alquiler temporario "} ({totalCountAlquilerTemp})
                                        </label>
                                    </div>
                                    : ''}
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer " + (!openType && 'close')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) =>  setOpenAge(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenPrice(false) + setOpenEnvironment(false) +setOpenOperation(false) + setOpenLocation(false) + setOpenType(!openType) + e.preventDefault()}>
                            Tipo de propiedad
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select">
                            {/* <option value="all">Tipo de propiedad</option> */}
                            {tokko_filters.data ? 
                                tokko_filters.data.objects.property_types.sort((a,b) => {getTypeCount(tokko_filters.data.objects,a) - getTypeCount(tokko_filters.data.objects,b)}).map((item,index) => (
                                    <div className={'not-closer flex-input ' + (getTypeCount(tokko_filters.data.objects,item) === 0 && 'opacity-filter')}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"type", {id:item.id,name:item.type})} checked={checkCurrentFilters(filters,"type",item.type)} type="checkbox" name="" id={item.type} value={item.type}/>
                                        <label className="not-closer" htmlFor={item.type}>
                                            <div className="square custom-span not-closer"></div>
                                            {item.type + " "}({getTypeCount(tokko_filters.data.objects,item)})
                                        </label>
                                    </div>
                                    )
                                    )
                                    :''
                                }
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer " + (!openLocation && 'close')}>
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) =>  setOpenAge(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenPrice(false) + setOpenEnvironment(false) + setOpenOperation(false) + setOpenType(false) + setOpenLocation(!openLocation) + e.preventDefault()}>
                            Ubicación
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer height-custom float-select">
                            <input type="text" className="searcher not-closer d-block mb-2 mt-3" placeholder="Buscar por ubicación" name="" value={searcher} onChange={(e) => setSearcher(e.target.value)} id="" />
                            <div className={"my-wrapper position-relative not-closer "}>
                                {!allLocations?.locations
                                    ?   <> </> 
                                    :   <>
                                            {searcher !== '' 
                                            ?   <>
                                                    {getGrandfathers(allLocations).map((item) => (
                                                        <>
                                                            {item[0].toLowerCase().includes(searcher.toLowerCase()) && (
                                                                <div className="d-flex px-lg-3 w-100 align-items-center not-closer justify-content-start">
                                                                    <label className={"option flex-input not-closer"}  >
                                                                        <input onChange={() => updateLocationByGrandFather(item)} checked={checkAllFatherActives(item)} id="" type="checkbox" className="radio-custom not-closer " name="location[]" value={item[0]} />
                                                                        <span className="custom-input not-closer square "></span> <div className="mark-up not-closer"> <Markup content={toMarkup(item[0],searcher)} /></div>
                                                                    </label>
                                                                </div>)}
                                                                {getFathers(item).map((father) => (
                                                                    <>
                                                                        {(father[0].toLowerCase().includes(searcher.toLowerCase()) &&
                                                                            <div className="d-flex px-lg-3 w-100 align-items-center not-closer justify-content-start">
                                                                                <label className={"option not-closer flex-input"}  >
                                                                                    <input onChange={() => updateLocationByParent(father) } checked={checkCitiesActive(father) && !checkAllFatherActives(item)}  type="checkbox" className="not-closer  radio-custom"  name="location[]" value={father[0]} />
                                                                                    <span className="not-closer custom-input square "></span> <div className="mark-up not-closer"> <Markup content={toMarkup(father[0],searcher)} /></div>
                                                                                </label>
                                                                            </div>)}
                                                                        {getChildrens(father).map((children) => (
                                                                            children[0].toLowerCase().includes(searcher.toLowerCase()) && <div className="d-flex px-lg-3 w-100 align-items-center">
                                                                                <label className={"not-closer option flex-input"}  >
                                                                                    <input id="" type="checkbox" checked={filters.location.find(element => element.id === children[1].id) && !checkAllFatherActives(item)} onChange={() => updateLocationByChildren({id:children[1].id,name:children[0]})} className="not-closer radio-custom" name="location[]" value={children[0]} />
                                                                                    <span className="not-closer custom-input square "></span> <div className="mark-up not-closer"> <Markup content={toMarkup(children[0],searcher)} /></div>
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                ))}
                                                            </>
                                                        ))}
                                                        {checkResults("location") && <span className="mb-4">No hay resultados sobre <b> "{searcher}"</b>.</span>}
                                                </>
                                            :   getGrandfathers(allLocations).map((item,i) => ( // List the grandfathers
                                                    <div className={""}>
                                                        <div className="d-flex px-lg-3 w-100 align-items-center not-closer justify-content-start w-custom">
                                                            <div className={"me-2 open-collapse not-closer collapsed d-inline-flex"} data-bs-toggle="collapse" data-bs-target={"#collapse-location-"+normalizeName(item[0])} aria-expanded="false" aria-controls={"collapse-location-"+normalizeName(item[0])}></div>
                                                            <label className={"mb-0 option flex-input not-closer"}  >
                                                                <input onChange={() => updateLocationByGrandFather(item)} checked={checkAllFatherActives(item)} id="" type="checkbox" className="radio-custom not-closer " name="location[]" value={item[0]} />
                                                                <span className="custom-input not-closer square"></span> {item[0].replace("\*\*(.*)\*\*", "<b>$1</b>")}
                                                            </label>
                                                        </div>
                                                        <div className="detail-collapse not-height collapse not-closer " id={"collapse-location-"+normalizeName(item[0])}>
                                                            {getFathers(item).map((father) => ( // List the fathers
                                                                <div className="d-flex flex-column justify-content-content mb-0 align-items-start not-closer w-custom childrens">
                                                                    <div className="d-flex align-items-center w-100 justify-content-center not-closer ">
                                                                        <div className={"me-2 not-closer open-collapse collapsed d-inline-flex " + (getChildrens(father).length <= 1 ? ' opacity ' : '')} data-bs-toggle="collapse" data-bs-target={"#collapse-location-"+normalizeName(father[0])} aria-expanded="false" aria-controls={"collapse-location-"+normalizeName(father[0])}></div>
                                                                        <label className={"option not-closer flex-input"}  >
                                                                        {/* +  updateFilters(setFilters,filters,"location", {id:father[1].id,name:father[0]}) */}
                                                                            <input onChange={() => updateLocationByParent(father) } checked={checkCitiesActive(father) && !checkAllFatherActives(item)}  type="checkbox" className="not-closer radio-custom"  name="location[]" value={father[0]} />
                                                                            <span className="not-closer custom-input square my-0"></span> {father[0]}
                                                                        </label>
                                                                    </div>
                                                                    <div className="detail-collapse children not-height collapse not-closer mb-2 childrens" id={"collapse-location-"+normalizeName(father[0])}>
                                                                        {getChildrens(father).map((children) => ( // List the childrens
                                                                            <label className={"not-closer option flex-input"}  >
                                                                                <input id="" type="checkbox" checked={filters.location.find(element => element.id === children[1].id) && !checkAllFatherActives(item)} onChange={() => updateLocationByChildren({id:children[1].id,name:children[0]})} className="not-closer radio-custom" name="location[]" value={children[0]} />
                                                                                <span className="not-closer  custom-input square my-0"></span> {children[0]}
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            <div className="control-float position-sticky w-100 d-flex justify-content-between not-closer">
                                                <buttton onClick={() => updateFilters(setAuxFilters,filters,"allLocations", [])} className="btn btn-white me-1 not-closer">
                                                    Limpiar
                                                </buttton>
                                                <buttton onClick={() => setOpenLocation(false)} className="btn btn-black ms-1 border not-closer">
                                                    Aplicar
                                                </buttton>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer position-relative " + (!openEnvironment && ' close ') + ((!checkEnvironmentExist()) ? ' d-lg-none ' : ' d-lg-flex')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => (filters.bedrooms !== '' ? setBedrooms({min:filters.bedrooms.split("-")[0],max:filters.bedrooms.split("-")[1]}) : '')  + (filters.environments !== '' ? setEnvironments({min:filters.environments.split("-")[0],max:filters.environments.split("-")[1]}) : '') +  setOpenAge(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenPrice(false) + setOpenOperation(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(!openEnvironment) + e.preventDefault()}>
                            Amb | Dorm
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select not-height">
                            <h5 className="not-closer title">
                                Ambientes
                            </h5>
                            <div className='d-flex not-closer'>
                                <div className={"d-none d-lg-block content-field not-closer me-2 " + (!openMinEnvironment && 'close')} >
                                    <div className="not-closer dropdown-select minimal-select btn btn-main no-hover justify-content-between" onClick={() => setOpenMinEnvironment(!openMinEnvironment)}>
                                        {environments.min === '' ? 'Minimo' : (environments.min.toString() === '99' ? "+9" : environments.min) + ' amb'}
                                        <i className='icon-arrow-light not-closer'></i>
                                    </div>
                                    <div className="w-auto not-closer float-select position-relative">
                                        <div className={"not-closer flex-input " + (((!checkEnvironment(listDynamicFilters.data.objects,1)))  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("min",1)}  checked={environments.min.toString() === "1"} type="radio" id="amb-1" name="roomsMin" value="1" />
                                            <label className="not-closer" htmlFor={"amb-1"}>
                                                <div className="custom-span not-closer"></div>1 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +(((!checkEnvironment(listDynamicFilters.data.objects,2))) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("min",2) } checked={environments.min.toString() === "2"} type="radio" id="amb-2" name="roomsMin" value="2" />
                                            <label className="not-closer" htmlFor={"amb-2"}>
                                                <div className="custom-span not-closer"></div>2 amb
                                            </label> 
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,3))) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("min",3)}  checked={environments.min.toString() === "3"} type="radio" id="amb-3" name="roomsMin" value="3" />
                                            <label className="not-closer" htmlFor={"amb-3"}>
                                                <div className="custom-span not-closer"></div>3 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " + (((!checkEnvironment(listDynamicFilters.data.objects,4))) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("min",4)} checked={environments.min.toString() === "4"} type="radio" id="amb-4" name="roomsMin" value="4" />
                                            <label className="not-closer" htmlFor={"amb-4"}>
                                                <div className="custom-span not-closer"></div>4 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,5))) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("min",5)}  checked={environments.min.toString() === "5"} type="radio" id="amb-5" name="roomsMin" value="5" />
                                            <label className="not-closer" htmlFor={"amb-5"}>
                                                <div className="custom-span not-closer"></div>5 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,6))) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("min",6) } checked={environments.min.toString() === "6"} id="amb-6" name="roomsMin" value="6" />
                                            <label className="not-closer" htmlFor={"amb-6"}>
                                                <div className="custom-span not-closer"></div>6 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,7))) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("min",7)} checked={environments.min.toString() === "7"} id="amb-7" name="roomsMin" value="7" />
                                            <label className="not-closer" htmlFor={"amb-7"}>
                                                <div className="custom-span not-closer"></div>7 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,8))) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("min",8)} checked={environments.min.toString() === "8"} id="amb-8" name="roomsMin" value="8" />
                                            <label className="not-closer" htmlFor={"amb-8"}>
                                                <div className="custom-span not-closer"></div>8 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  (((!checkEnvironment(listDynamicFilters.data.objects,9))) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("min",99)} checked={environments.min.toString() === "99"} id="amb-9" name="roomsMin" value="9" />
                                            <label className="not-closer" htmlFor={"amb-9"}>
                                                <div className="custom-span not-closer"></div>+9 amb
                                            </label>  
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-none d-lg-block content-field not-closer ms-2 " + (!openMaxEnvironment && 'close')} >
                                    <div className="not-closer dropdown-select minimal-select btn btn-main no-hover" onClick={() => setOpenMaxEnvironment(!openMaxEnvironment)}>
                                    {environments.max === '' ? 'Máximo' : (environments.max.toString() === '99' ? '+9' : environments.max.toString()) + ' amb'}
                                        <i className='icon-arrow-light not-closer'></i>
                                    </div>
                                    <div className="not-closer float-select position-relative">
                                        <div className={"not-closer flex-input " + ((!checkEnvironment(listDynamicFilters.data.objects,1) || environments.min > 1)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("max",1)}  checked={environments.max.toString() === "1"} type="radio" id="amb-1-max" name="roomsMax" value="1" />
                                            <label className="not-closer" htmlFor={"amb-1-max"}>
                                                <div className="custom-span not-closer"></div>1 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,2) || environments.min > 2)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("max",2) } checked={environments.max.toString() === "2"} type="radio" id="amb-2-max" name="roomsMax" value="2" />
                                            <label className="not-closer" htmlFor={"amb-2-max"}>
                                                <div className="custom-span not-closer"></div>2 amb
                                            </label> 
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,3) || environments.min > 3)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("max",3)}  checked={environments.max.toString() === "3"} type="radio" id="amb-3-max" name="roomsMax" value="3" />
                                            <label className="not-closer" htmlFor={"amb-3-max"}>
                                                <div className="custom-span not-closer"></div>3 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,4) || environments.min > 4)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("max",4)} checked={environments.max.toString() === "4"} type="radio" id="amb-4-max" name="roomsMax" value="4" />
                                            <label className="not-closer" htmlFor={"amb-4-max"}>
                                                <div className="custom-span not-closer"></div>4 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,5) || environments.min > 5)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateEnvironment("max",5)}  checked={environments.max.toString() === "5"} type="radio" id="amb-5-max" name="roomsMax" value="5" />
                                            <label className="not-closer" htmlFor={"amb-5-max"}>
                                                <div className="custom-span not-closer"></div>5 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " + ((!checkEnvironment(listDynamicFilters.data.objects,6) || environments.min > 6)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("max",6) } checked={environments.max.toString() === "6"} id="amb-6-max" name="roomsMax" value="6" />
                                            <label className="not-closer" htmlFor={"amb-6-max"}>
                                                <div className="custom-span not-closer"></div>6 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,7) || environments.min > 7)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("max",7)} checked={environments.max.toString() === "7"} id="amb-7-max" name="roomsMax" value="7" />
                                            <label className="not-closer" htmlFor={"amb-7-max"}>
                                                <div className="custom-span not-closer"></div>7 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,8) || environments.min > 8)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("max",8)} checked={environments.max.toString() === "8"} id="amb-8-max" name="roomsMax" value="8" />
                                            <label className="not-closer" htmlFor={"amb-8-max"}>
                                                <div className="custom-span not-closer"></div>8 amb
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkEnvironment(listDynamicFilters.data.objects,9) || environments.min > 9)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateEnvironment("max",99)} checked={environments.max.toString() === "99"} id="amb-9-max" name="roomsMax" value="9" />
                                            <label className="not-closer" htmlFor={"amb-9-max"}>
                                                <div className="custom-span not-closer"></div>+9 amb
                                            </label>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {listDynamicFilters.data ? 
                                <>
                                </>
                                :''
                            }
                            <h5 className={"not-closer title " + (!checkBedroomExist() && 'd-none')}>
                                Dormitorios
                            </h5>
                            <div className={'d-flex not-closer ' + (!checkBedroomExist() && 'd-none')}>
                                <div className={"d-none d-lg-block content-field not-closer me-2 " + (!openMinBedrooms && 'close')} >
                                    <div className="not-closer dropdown-select minimal-select btn btn-main no-hover justify-content-between" onClick={() => setOpenMinBedrooms(!openMinBedrooms)}>
                                        {bedrooms.min === '' ? 'Minimo' : (bedrooms.min.toString() === '99' ? "+9" : bedrooms.min) + ' dorm'}
                                        <i className='icon-arrow-light not-closer'></i>
                                    </div>
                                    <div className="w-auto not-closer float-select position-relative">
                                        <div className={"not-closer flex-input " + ((!checkBedroom(listDynamicFilters.data.objects,1)) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("min",1)}  checked={bedrooms.min.toString() === "1"} type="radio" id="bed-1" name="bedMin" value="1" />
                                            <label className="not-closer" htmlFor={"bed-1"}>
                                                <div className="custom-span not-closer"></div>1 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " + ((!checkBedroom(listDynamicFilters.data.objects,2)) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("min",2) } checked={bedrooms.min.toString() === "2"} type="radio" id="bed-2-min" name="bedMin" value="2" />
                                            <label className="not-closer" htmlFor={"bed-2-min"}>
                                                <div className="custom-span not-closer"></div>2 dorm
                                            </label> 
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,3)) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("min",3)}  checked={bedrooms.min.toString() === "3"} type="radio" id="bed-3-min" name="bedMin" value="3" />
                                            <label className="not-closer" htmlFor={"bed-3-min"}>
                                                <div className="custom-span not-closer"></div>3 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " + ((!checkBedroom(listDynamicFilters.data.objects,4)) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("min",4)} checked={bedrooms.min.toString() === "4"} type="radio" id="bed-4-min" name="bedMin" value="4" />
                                            <label className="not-closer" htmlFor={"bed-4-min"}>
                                                <div className="custom-span not-closer"></div>4 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,5)) && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("min",5)}  checked={bedrooms.min.toString() === "5"} type="radio" id="bed-5-min" name="bedMin" value="5" />
                                            <label className="not-closer" htmlFor={"bed-5-min"}>
                                                <div className="custom-span not-closer"></div>5 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,6)) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("min",6) } checked={bedrooms.min.toString() === "6"} id="bed-6-min" name="bedMin" value="6" />
                                            <label className="not-closer" htmlFor={"bed-6-min"}>
                                                <div className="custom-span not-closer"></div>6 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,7)) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("min",7)} checked={bedrooms.min.toString() === "7"} id="bed-7-min" name="bedMin" value="7" />
                                            <label className="not-closer" htmlFor={"bed-7-min"}>
                                                <div className="custom-span not-closer"></div>7 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,8)) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("min",8)} checked={bedrooms.min.toString() === "8"} id="bed-8-min" name="bedMin" value="8" />
                                            <label className="not-closer" htmlFor={"bed-8-min"}>
                                                <div className="custom-span not-closer"></div>8 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,9)) && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("min",99)} checked={bedrooms.min.toString() === "99"} id="bed-9-min" name="bedMin" value="9" />
                                            <label className="not-closer" htmlFor={"bed-9-min"}>
                                                <div className="custom-span not-closer"></div>+9 dorm
                                            </label>  
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-none d-lg-block content-field not-closer ms-2 " + (!openMaxBedrooms && 'close')} >
                                    <div className="not-closer dropdown-select minimal-select btn btn-main no-hover " onClick={() => setOpenMaxBedrooms(!openMaxBedrooms)}>
                                    {bedrooms.max === '' ? 'Máximo' : (bedrooms.max.toString() === '99' ? "+9" : bedrooms.max) + ' dorm'}
                                        <i className='icon-arrow-light not-closer'></i>
                                    </div>
                                    <div className="not-closer float-select position-relative">
                                        <div className={"not-closer flex-input " + ((!checkBedroom(listDynamicFilters.data.objects,1) || bedrooms.min > 1)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("max",1)}  checked={bedrooms.max.toString() === "1"} type="radio" id="bed-1-max" name="bedMax" value="1" />
                                            <label className="not-closer" htmlFor={"bed-1-max"}>
                                                <div className="custom-span not-closer"></div>1 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,2) || bedrooms.min > 2)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("max",2) } checked={bedrooms.max.toString() === "2"} type="radio" id="bed-2-max" name="bedMax" value="2" />
                                            <label className="not-closer" htmlFor={"bed-2-max"}>
                                                <div className="custom-span not-closer"></div>2 dorm
                                            </label> 
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,3) || bedrooms.min > 3)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("max",3)}  checked={bedrooms.max.toString() === "3"} type="radio" id="bed-3-max" name="bedMax" value="3" />
                                            <label className="not-closer" htmlFor={"bed-3-max"}>
                                                <div className="custom-span not-closer"></div>3 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,4) || bedrooms.min > 4)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("max",4)} checked={bedrooms.max.toString() === "4"} type="radio" id="bed-4-max" name="bedMax" value="4" />
                                            <label className="not-closer" htmlFor={"bed-4-max"}>
                                                <div className="custom-span not-closer"></div>4 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,5) || bedrooms.min > 5)  && "d-none")}>
                                            <input className="not-closer" onChange={() => updateBedroom("max",5)}  checked={bedrooms.max.toString() === "5"} type="radio" id="bed-5-max" name="bedMax" value="5" />
                                            <label className="not-closer" htmlFor={"bed-5-max"}>
                                                <div className="custom-span not-closer"></div>5 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " + ((!checkBedroom(listDynamicFilters.data.objects,6) || bedrooms.min > 6)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("max",6) } checked={bedrooms.max.toString() === "6"} id="bed-6-max" name="bedMax" value="6" />
                                            <label className="not-closer" htmlFor={"bed-6-max"}>
                                                <div className="custom-span not-closer"></div>6 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,7) || bedrooms.min > 7)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("max",7)} checked={bedrooms.max.toString() === "7"} id="bed-7-max" name="bedMax" value="7" />
                                            <label className="not-closer" htmlFor={"bed-7-max"}>
                                                <div className="custom-span not-closer"></div>7 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,8) || bedrooms.min > 8)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("max",8)} checked={bedrooms.max.toString() === "8"} id="bed-8-max" name="bedMax" value="8" />
                                            <label className="not-closer" htmlFor={"bed-8-max"}>
                                                <div className="custom-span not-closer"></div>8 dorm
                                            </label>  
                                        </div>
                                        <div className={"not-closer flex-input " +  ((!checkBedroom(listDynamicFilters.data.objects,9) || bedrooms.min > 9)  && "d-none")}>
                                            <input className="not-closer" type="radio" onChange={() => updateBedroom("max",99)} checked={bedrooms.max.toString() === "99"} id="bed-9-max" name="bedMax" value="9" />
                                            <label className="not-closer" htmlFor={"bed-9-max"}>
                                                <div className="custom-span not-closer"></div>+9 dorm
                                            </label>  
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            {listDynamicFilters.data ? 
                                <>
                                    
                                </>
                                :''
                            }
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer position-relative " + (!openAge && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) =>  setOpenAge(!openAge) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenPrice(false) + setOpenOperation(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(false) + e.preventDefault()}>
                            Antigüedad
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select">
                            {listDynamicFilters.data ? 
                                <>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "En construccion")} checked={checkCurrentFilters(filters,"age",'En construccion')} type="radio" name="age" id={"age-0"} value={-1}/>
                                        <label className="not-closer" htmlFor={"age-0"}>
                                            <div className="radio custom-span not-closer"></div>
                                            En construcción
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "A estrenar")} checked={checkCurrentFilters(filters,"age",'A estrenar')} type="radio" name="age" id={"age-1"} value={-1}/>
                                        <label className="not-closer" htmlFor={"age-1"}>
                                            <div className="radio custom-span not-closer"></div>
                                            A estrenar
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "1 a 5 años")}  checked={checkCurrentFilters(filters,"age", "1 a 5 años")} type="radio" id="age-2" name="age" value="0" />
                                        <label className="not-closer" htmlFor={"age-2"}>
                                            <div className="radio custom-span not-closer"></div>
                                            1 a 5 años
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "6 a 10 años")}  checked={checkCurrentFilters(filters,"age", "6 a 10 años")} type="radio" id="age-3" name="age" value="0" />
                                        <label className="not-closer" htmlFor={"age-3"}>
                                            <div className="radio custom-span not-closer"></div>
                                            6 a 10 años
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "11 a 20 años")}  checked={checkCurrentFilters(filters,"age", "11 a 20 años")} type="radio" id="age-4" name="age" value="0" />
                                        <label className="not-closer" htmlFor={"age-4"}>
                                            <div className="radio custom-span not-closer"></div>
                                            11 a 20 años
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "21 a 50 años")}  checked={checkCurrentFilters(filters,"age", "21 a 50 años")} type="radio" id="age-5" name="age" value="0" />
                                        <label className="not-closer" htmlFor={"age-5"}>
                                            <div className="radio custom-span not-closer"></div>
                                            21 a 50 años
                                        </label>
                                    </div>
                                    <div className={"not-closer flex-input"}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"age", "+ 50 años")}  checked={checkCurrentFilters(filters,"age", "+ 50 años")} type="radio" id="age-6" name="age" value="0" />
                                        <label className="not-closer" htmlFor={"age-6"}>
                                            <div className="radio custom-span not-closer"></div>
                                            + 50 años
                                        </label>
                                    </div>
                                </>
                                :''
                            }
                            <div className="view-all not-closer">
                                <span onClick={() => setAuxFilters({...auxFilters,age: ''})} className='not-closer d-block btn btn-black mx-2 py-2'>Ver todas</span>
                            </div>
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer " + (!openPrice && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => setOpenAge(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenOperation(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(false) + setOpenPrice(!openPrice) + e.preventDefault()}>
                            Precio
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select w-auto">
                            <div className='d-flex not-closer'>
                                <div className={"not-closer flex-input no-hover pe-3"}>
                                    <input className="not-closer" onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceType : "pesos"})} checked={auxiliarValuesPrice.priceType.toLowerCase() === 'pesos'} type="radio" id="price-ars" name="price" value="2" />
                                    <label className="not-closer" htmlFor='price-ars'>
                                        <div className="custom-span not-closer radio"></div>Pesos
                                    </label> 
                                </div>
                                <div className={"not-closer flex-input no-hover pe-5"}>
                                    <input className="not-closer" onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceType : "dolares"})} checked={auxiliarValuesPrice.priceType.toLowerCase() === 'dolares'} type="radio" id="price-dolar" name="price" value="2" />
                                    <label className="not-closer" htmlFor='price-dolar'>
                                        <div className="custom-span not-closer radio"></div>Dolares
                                    </label> 
                                </div>
                            </div>
                            <div className="row px-3 mt-3 input-range not-closer">
                                <div className="col-6 not-closer">
                                    <input className="not-closer" 
                                        type="text"
                                        placeholder='Desde'
                                        disabled={!auxiliarValuesPrice.priceType} 
                                        onFocus={() => setHelpers({...helpers,minprice:1})}
                                        onChange={(e) => setAuxiliarPrice({...auxiliarValuesPrice, priceMin : e.target.value})}
                                        value={auxiliarValuesPrice.priceMin}
                                    />
                                    <div className={"cursor-pointer not-closer price-helper " + (helpers.minprice ? '' : ' d-none ')}>
                                        {pricesDefault.map((price,index) => (
                                            <div onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceMin : price}) + setHelpers({...helpers,minprice:0})} key={index} className="price-helper-item not-closer ">{Intl.NumberFormat("de-DE").format(price)}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6 not-closer">
                                    <input className="not-closer" 
                                        type="text"
                                        disabled={!auxiliarValuesPrice.priceType} 
                                        placeholder='Hasta'
                                        onFocus={() => setHelpers({...helpers,maxprice:1})}
                                        onChange={(e) => setAuxiliarPrice({...auxiliarValuesPrice, priceMax : e.target.value})}
                                        value={auxiliarValuesPrice.priceMax}
                                    />
                                    <div className={"cursor-pointer price-helper not-closer " + (helpers.maxprice ? '' : 'd-none')}>
                                        {pricesDefault.map((price,index) => (
                                            price > auxiliarValuesPrice.priceMin ? 
                                                <div onClick={() => setAuxiliarPrice({...auxiliarValuesPrice, priceMax : price}) + setHelpers({...helpers,maxprice:0})} key={index} className="price-helper-item not-closer ">{Intl.NumberFormat("de-DE").format(price)}</div>
                                            :
                                                null
                                            ))}
                                    </div>
                                </div>
                                    <div className="view-all not-closer">
                                        <button disabled={!auxiliarValuesPrice.priceType || !auxiliarValuesPrice.priceMax || !auxiliarValuesPrice.priceMin } style={{width:"-webkit-fill-available"}} className="not-closer d-block btn btn-black mx-2 py-2" onClick={(e) => sendAuxiliar() + e.preventDefault() + setOpenPrice(false)}>Aplicar</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={"d-none d-lg-flex content-field not-closer " + (!openSurface && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => setOpenAge(false) + setOpenServices(false) + setOpenOperation(false) +  setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(false) + setOpenPrice(false) + setOpenSurface(!openSurface) + e.preventDefault()}>
                            Superficie
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select w-auto">
                            <div className='d-flex'>
                                <div className={"not-closer flex-input no-hover pe-5"}>
                                    <input className="not-closer"onClick={() => setAuxiliarSurface({...auxiliarValuesSurface, surfaceType : "cubierta"})} checked={auxiliarValuesSurface.surfaceType === 'cubierta'} type="radio" id="cub" name="surface_type" value="CUB" />
                                    <label className="not-closer" htmlFor='cub'>
                                        <div className="custom-span not-closer radio"></div>Cubierta
                                    </label> 
                                </div>
                                <div className={"not-closer flex-input no-hover pe-5"}>
                                    <input className="not-closer"onClick={() => setAuxiliarSurface({...auxiliarValuesSurface, surfaceType : "total"})} checked={auxiliarValuesSurface.surfaceType === 'total'} type="radio" id="tot" name="surface_type" value="TOT" />
                                    <label className="not-closer" htmlFor='tot'>
                                        <div className="custom-span not-closer radio"></div>Total
                                    </label> 
                                </div>
                            </div>
                            <div className="row px-3 mt-3 not-closer input-range">
                                <div className="col-6 not-closer">
                                    <input className="not-closer"
                                        type="text"
                                        placeholder='Desde'
                                        disabled={!auxiliarValuesSurface.surfaceType} 
                                        onChange={(e) => setAuxiliarSurface({...auxiliarValuesSurface, surfaceMin : e.target.value})}
                                        value={(auxiliarValuesSurface.surfaceMin)}
                                    />
                                </div>
                                <div className="col-6 d-flex not-closer align-items-center">
                                    <input className="not-closer"
                                        type="text"
                                        placeholder='Hasta'
                                        disabled={!auxiliarValuesSurface.surfaceType} 
                                        onChange={(e) => setAuxiliarSurface({...auxiliarValuesSurface, surfaceMax : e.target.value})}
                                        value={(auxiliarValuesSurface.surfaceMax)}
                                    />
                                    <div className="ms-1 mb-2 not-closer" style={{fontSize:".8rem"}}>m2</div>
                                </div>
                                <div className="col-12 not-closer">
                                    <button disabled={!auxiliarValuesSurface.surfaceType || !auxiliarValuesSurface.surfaceMax || !auxiliarValuesSurface.surfaceMin}  className="d-block w-100 not-closer btn btn-black my-2 px-4 py-2 " onClick={(e) => sendAuxiliar() + e.preventDefault() + setOpenSurface(false)}>Aplicar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"content-field " + (getGenerals(listDynamicFilters.data.objects).length === 0 ? ' d-none ' : 'd-none d-lg-flex ') + (!openGenerals && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => setOpenAge(false) + setOpenOperation(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(false) + setOpenPrice(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(!openGenerals) + e.preventDefault()}>
                            Generales
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select w-auto">
                            {listDynamicFilters.data ? 
                                getGenerals(listDynamicFilters.data.objects).sort((a,b) => {getTagCount(listDynamicFilters.data.objects,a) - getTagCount(listDynamicFilters.data.objects,b)}).map((item,index) => (
                                    <div className={'not-closer flex-input ' + (getTagCount(listDynamicFilters.data.objects,item) === 0 && 'd-none')}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"generals", {id:item.tag_id,name:item.tag_name})} checked={checkCurrentFilters(filters,"generals", {id:item.tag_id,name:item.tag_name})} type="checkbox" id={item.tag_id} name="amenities" value={item.tag_id}/>
                                        <label className="not-closer" htmlFor={item.tag_id}>
                                            <div className="square custom-span not-closer"></div>
                                            {item.tag_name}
                                        </label>
                                    </div>
                                    )
                                    )
                                    :''
                                }
                        </div>
                    </div>
                    {/* <div className={"content-field " + (getServices(listDynamicFilters.data.objects).length === 0 ? ' d-none ' : 'd-none d-lg-flex ') + (!openServices && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => setOpenAge(false) + setOpenOperation(false) + setOpenLocation(false)+ setOpenTypeEnvoirments (false) + setOpenType(false) + setOpenEnvironment(false) + setOpenPrice(false) + setOpenSurface(false) + setOpenGenerals(false) + setOpenServices(!openServices) + e.preventDefault()}>
                            Servicios
                            <i ></i>
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select w-auto">
                            {listDynamicFilters.data ? 
                                getServices(listDynamicFilters.data.objects).sort((a,b) => {getTagCount(listDynamicFilters.data.objects,a) - getTagCount(listDynamicFilters.data.objects,b)}).map((item,index) => (
                                    <div className={'not-closer flex-input ' + (getTagCount(listDynamicFilters.data.objects,item) === 0 && 'd-none')}>
                                        <input className="not-closer" onChange={() => updateFilters(setAuxFilters,auxFilters,"services", {id:item.tag_id,name:item.tag_name})} checked={checkCurrentFilters(filters,"services", {id:item.tag_id,name:item.tag_name})} type="checkbox" id={item.tag_id} name="services[]" value={item.tag_id} />
                                        <label className="not-closer" htmlFor={item.tag_id}>
                                            <div className="square custom-span not-closer"></div>
                                            {item.tag_name}
                                        </label>
                                    </div>
                                    )
                                    )
                                    :''
                                }
                        </div>
                    </div> */}
                    {/* <div className={"d-none d-lg-flex content-field not-closer " + (!openTypeEnvoirments && ' close ')} >
                        <div className="not-closer dropdown-select btn btn-main no-hover" onClick={(e) => setOpenOperation(false) + setOpenLocation(false) + setOpenType(false) + setOpenEnvironment(false) + setOpenPrice(false) + setOpenSurface(false) + setOpenServices(false) + setOpenTypeEnvoirments(false) + setOpenGenerals(false) + setOpenServices(false) + setOpenTypeEnvoirments(!openTypeEnvoirments) + e.preventDefault()}>
                            Tipo de ambientes
                            <i className='icon-arrow-light'></i>
                        </div>
                        <div className="not-closer float-select w-auto">
                            {listDynamicFilters.data ? 
                                getEnvironment(listDynamicFilters.data.objects).sort((a,b) => {getTagCount(listDynamicFilters.data.objects,a) - getTagCount(listDynamicFilters.data.objects,b)}).map((item,index) => (
                                    <div className={'not-closer flex-input ' + (getTagCount(listDynamicFilters.data.objects,item) === 0 && 'd-none')}>
                                        <input onChange={() => updateFilters(setAuxFilters,auxFilters,"environments_types", {id:item.tag_id,name:item.tag_name})} checked={checkCurrentFilters(filters,"environments_types", {id:item.tag_id,name:item.tag_name})} type="checkbox" name="" id={item.tag_id} value={item.tag_id}/>
                                        <label className="not-closer" htmlFor={item.tag_id}>
                                            <div className="square custom-span not-closer"></div>
                                            {item.tag_name}
                                        </label>
                                    </div>
                                    )
                                    )
                                    :''
                                }
                        </div>
                    </div> */}
                    <div className="d-lg-none content-field cursor-pointer">
                        <div className="dropdown-select btn btn-white border d-flex align-items-center custom-filter" onClick={() => setMenu(true)} >
                            <span>Filtros de búsqueda</span>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex content-field not-closer cursor-pointer">
                        <div className="dropdown-select btn btn-main border custom-clean"
                            onClick={() =>
                                setAuxFilters((auxFilters) => ({
                                    location: [],
                                    type: [],
                                    operation: location[1],
                                    environments: '',
                                    bedrooms: "",
                                    price: {
                                    type: "",
                                    priceMin: 0,
                                    priceMax: 50000000,
                                    },
                                    surface: {
                                    type: "",
                                    surfaceMin: 0,
                                    surfaceMax: 10000,
                                    },
                                    environments_types: [],
                                    generals: [],
                                    services: [],
                                    specials: [],
                                })) +
                                setAuxiliarPrice({
                                    priceType:'',
                                    priceMin:'',
                                    priceMax:'',
                                })+
                                setAuxiliarSurface({
                                    surfaceType:'',
                                    surfaceMin:'',
                                    surfaceMax:'',
                                }) + 
                                dispatch(REDUX_UPDATE_COORDINATES([]))}>
                                    Limpiar filtros
                        </div>
                    </div>
                    <div className="control-side position-relatve on-bar d-flex justify-content-center align-items-center not-closer">
                        <button className={"btn dropdown-select align-items-center btn-draw me-lg-3 me-1 p-2 " + ((polygonCoordinates.length === 0) || viewId !== 2 ? " d-none" : 'd-flex')} style={{lineHeight:"1"}} id="clean" onClick={(e) => e.preventDefault() + cleanerPolygon()}> 
                            <TrashSvg/>
                            <span className="d-none d-lg-block trash-legend">Eliminar selección</span>
                            <span className="d-lg-none trash-legend">Selección</span>
                        </button>
                    </div>
                </form>
            </aside>
        </>
    ):''
}

export default connect(state => ({
    filters: state.properties.filters,
    tokko_filters: state.properties.listFilters,
    total_tokko_filters: state.properties.totalListFilters,
    orders: state.properties.orders,
    listLocationFilters:state.properties.listLocationFilters,
    loading_locations: state.properties.loading_locations,
    listDynamicFilters: state.properties.listDynamicFilters,
    loading_dynamic:state.properties.loading_dynamic,
    totalCountAlquiler: state.properties.totalCountAlquiler,
    totalCountVenta: state.properties.totalCountVenta,
    totalCountAlquilerTemporario:state.properties.totalCountAlquilerTemporario,
    totalCountAlquilerTemp:state.properties.totalCountAlquilerTemp,
    api_key: state.settings.keys.tokko,
    totalCount: state.properties.totalCount,
    custom_tags: state.properties.custom_tags,
    polygonCoordinates:state.properties.polygonCoordinates,
    drawnPolygon: state.properties.drawnPolygon,
    allLocations:state.properties.allLocationFilters,
    loading_Alllocatons:state.properties.loading_Alllocatons,
}),null)(Sumary_Filter);