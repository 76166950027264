// import Card from '../property-card'
import React,{ useEffect } from 'react'

import { Link } from 'gatsby'

import { connect } from 'react-redux'
import {getEnvironment,getTypes,getLocations,getServices,checkCurrentFilters} from '../../helpers/helper.filters'
import Loading from '../Loading'
import Paginate from '../paginate'

const ClientSideOnlyLazy = React.lazy(() =>
  import("../property-card")
)

const Results = ({properties,totalCount,filters,totalListFilters,loading,loading_more,dispatch,viewId,menu,setMenu}) => {

    const {data} = totalListFilters

    useEffect(() => {
    }, [])

    const isSSR = typeof window === "undefined"

    //Constante provisoria hasta updatear mockdata **** Unixono
    const MediaSeo = false;

    useEffect(() => {
        scrollTo({top: (0) , behavior: 'smooth'})
        // const sideBarElement = document.getElementById('___gatsby'); // get id of sidebar
        // sideBarElement.scrollTo({top: (0) , behavior: 'smooth'}); // scroll to card
    },[properties])

    return (
        <section id="results" class={" " + (menu ? ' offset-lg-4 col-lg-8 ' : 'col-lg-12')}>
            <div class="row list d-flex justify-content-start">
                <div className={`col-12${properties.length === 0 && !loading ? '' : ' d-none'}`}><div class={"alert alert-info"}>No se encontraron resultados para su búsqueda.</div></div>
                    { 
                        !loading ?
                            properties.map((item,index) =>
                                <div class={"col-md-12 mb-4 " + (menu ? 'col-lg-6' : 'col-lg-3')}>
                                    {!isSSR && (
                                    <React.Suspense fallback={<div />}>
                                        <ClientSideOnlyLazy key={index} property={item} />
                                    </React.Suspense>
                                    )}
                                </div>
                            )
                        :
                            [1,2,3,4].map((item,index) => 
                                <div class={"col-md-12 mb-4 "  + (menu ? 'col-lg-6' : 'col-lg-3')}>
                                    {!isSSR && (
                                    <React.Suspense fallback={<div />}>
                                        <ClientSideOnlyLazy key={index} skeleton={true} />
                                    </React.Suspense>
                                    )}
                                </div>
                            )
                    }
                {loading_more ? <Loading /> : ''}
                <Paginate viewId={viewId} />
            </div>
            <div className={`seo-section mt-5 pt-lg-5 ${MediaSeo ? '' : 'd-none'}`}>
                <div className="item">
                    <h2>PROPIEDADES EN VENTA POR UBICACIÓN</h2>
                    <div className="row">
                        <div className="col-lg-10 col-xl-9 d-flex flex-wrap">
                            {data ? getLocations(data.objects).map((item,index) => (
                                <Link to={item.location_name.toLowerCase().replace(/ /g,"-")}>
                                    <p key={index} className={"tag-filter-button tag-button " + (checkCurrentFilters(filters,"location",item.location_name) ? 'active' : '')}>{item.location_name}</p>
                                </Link> 
                            )
                            ):''}
                        </div>
                    </div>
                </div>
                <div className="item">
                    <h2>TIPO DE PROPIEDADES EN VENTA</h2>
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-wrap">
                            {data ? getTypes(data.objects).map((item,index) => (
                                <Link to={item.type.toLowerCase().replace(/ /g,"-")}>
                                    <p key={index} className={"tag-filter-button tag-button " + (checkCurrentFilters(filters,"type",item.type) ? 'active' : '')}>{item.type}</p>
                                </Link>
                            )
                            ):''}
                        </div>
                    </div>
                </div>
                <div className="item">
                    <h2>PROPIEDADES EN VENTA POR AMBIENTES</h2>
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-wrap">
                            <Link to={"1-ambiente"}>
                                <p className={"tag-filter-button tag-button "+ (checkCurrentFilters(filters,"environments", "1 Ambiente") ? 'active' : '')}>1 Ambiente</p>
                            </Link>
                            <Link to={"2-ambientes"}>
                                <p className={"tag-filter-button tag-button "+ (checkCurrentFilters(filters,"environments", "2 Ambientes") ? 'active' : '')}>2 Ambientes</p>
                            </Link>
                            <Link to={"3-ambientes"}>
                                <p className={"tag-filter-button tag-button "+ (checkCurrentFilters(filters,"environments", "3 Ambientes") ? 'active' : '')}>3 Ambientes</p>
                            </Link>
                            <Link to={"4-ambientes"}>
                                <p className={"tag-filter-button tag-button "+ (checkCurrentFilters(filters,"environments", "4 Ambientes") ? 'active' : '')}>4 Ambientes</p>
                            </Link>
                            <Link to={"5-ambientes"}>
                                <p className={"tag-filter-button tag-button "+ (checkCurrentFilters(filters,"environments", "5 Ambientes") ? 'active' : '')}>5 Ambientes</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <h2>PROPIEDADES EN VENTA POR TIPO DE AMBIENTES</h2>
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-wrap">
                            {data ? getEnvironment(data.objects).map((item,index) => (
                                <Link to={item.tag_name.toLowerCase().replace(/ /g,"-")}>
                                    <p key={index} className={"tag-filter-button tag-button " + (checkCurrentFilters(filters,"environments_types", {id:item.tag_id,name:item.tag_name}) ? 'active' : '')}>{item.tag_name}</p>
                                </Link>
                            )
                            ):''}
                            {/* <p className="tag-filter-button">FILTER</p> */}
                        </div>
                    </div>
                </div>
                <div className="item">
                    <h2>PROPIEDADES EN VENTA POR SERVICIOS</h2>
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-wrap">
                            {data ? getServices(data.objects).map((item,index) => (
                                <Link to={item.tag_name.toLowerCase().replace(/ /g,"-")}>
                                    <p key={index} className={"tag-filter-button tag-button " + (checkCurrentFilters(filters,"services", {id:item.tag_id,name:item.tag_name}) ? 'active' : '')}>{item.tag_name}</p>
                                </Link>
                            )
                            ):''}
                            {/* <p className="tag-filter-button">FILTER</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    properties: state.properties.properties,
    totalCount: state.properties.totalCount,
    filters: state.properties.filters,
    totalListFilters: state.properties.totalListFilters,
    loading: state.properties.loading,
    loading_more: state.properties.loading_more
}),null)(Results);